import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { SidebarContainer } from 'r/components';

const PageLayoutSidebar = () => {
  const { pathname } = useLocation();

  return (
    <Box
      className="page-layout-sidebar"
      sx={{
        display: 'flex',
        '.layout-divider + .grid-header': { pt: 1 },
        backgroundColor: pathname.includes('integrations')
          ? 'background.white'
          : 'background.gray',
      }}
    >
      <SidebarContainer />
      <Box
        className="page-main"
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          position: 'relative',
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export { PageLayoutSidebar };
