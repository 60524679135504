import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, strokeWidth, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 10L10 1L19 10"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Chevron = svgWrapper(SvgComponent, {
  width: '20px',
  height: '11px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  strokeWidth: '2',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  strokeWidth: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Chevron };
