import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 22"
    fill="none"
    ref={forwardedRef}
    {...props}
  >
    <path
      fill={strokeColor}
      fillRule="evenodd"
      d="M12.112 3.82a1.652 1.652 0 1 0-2.338-2.336 1.652 1.652 0 1 0 2.338 2.337Zm.707.708a2.651 2.651 0 0 0 0-3.751A2.654 2.654 0 0 0 8.8 4.219L5.49 7.529a3.832 3.832 0 1 0 .588 6.562c.02.03.042.058.068.084l2.775 2.774.006.006a2.652 2.652 0 0 0 3.892 3.601 2.651 2.651 0 0 0 0-3.75 2.655 2.655 0 0 0-3.117-.47.5.5 0 0 0-.073-.093l-2.776-2.775a.502.502 0 0 0-.059-.05c.362-.44.629-.963.766-1.535.024.003.05.005.074.005h5.496a2.655 2.655 0 0 0 5.15-.904 2.653 2.653 0 0 0-5.306-.096h-5.31a3.822 3.822 0 0 0-1.32-2.799l3.19-3.188a2.655 2.655 0 0 0 3.285-.373Zm2.807 8.109a1.653 1.653 0 1 0 0-3.306 1.653 1.653 0 0 0 0 3.306Zm-3.514 4.875a1.652 1.652 0 1 1-2.338 2.337 1.652 1.652 0 1 1 2.338-2.337Zm-5.446-6.527a2.832 2.832 0 1 1-5.664-.002 2.832 2.832 0 0 1 5.664.002Z"
      clipRule="evenodd"
      opacity="0.6"
    />
  </svg>
);

const Channel = svgWrapper(SvgComponent, {
  width: '19px',
  height: '22px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Channel };
