import { Typography } from '@mui/material';
import { shape, node } from 'prop-types';

const DisplayXLarge = ({ children, ...props }) => (
  <Typography
    className="display-xl"
    variant="displayXLarge"
    component="h1"
    {...props}
  >
    {children}
  </Typography>
);

DisplayXLarge.defaultProps = {
  props: {},
};

DisplayXLarge.propTypes = {
  children: node.isRequired,
  props: shape({}),
};

export { DisplayXLarge };
