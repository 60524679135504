import PropTypes from 'prop-types';
import { DialogContent, Dialog } from '@mui/material';
import { useState, useMemo, createContext, useContext } from 'react';
import { DialogTitle } from './DialogTitle';

const ImagePreviewDialog = ({
  open,
  onClose,
  sx = {},
  children,
  dataTestid = '',
} = {}) => {
  if (!open) {
    return null;
  }

  const imagePreviewSx = {
    ...sx,
    '&.MuiDialog-root': {
      '& .MuiPaper-root': {
        maxWidth: '776px',
        minHeight: '624px',
        padding: '0',
      },
    },
  };
  return (
    <Dialog data-testid={dataTestid} open onClose={onClose} sx={imagePreviewSx}>
      <DialogTitle onClose={onClose} />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

ImagePreviewDialog.defaultProps = {
  dataTestid: '1',
  sx: {},
};

ImagePreviewDialog.propTypes = {
  /**
   * onClick handler for close button.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Open state for dialog.
   */
  open: PropTypes.bool.isRequired,
  /**
   * (Optional) Additional style props.
   */
  sx: PropTypes.shape({}),
  /**
   * (Optional) a testid for finding the element during tests
   */
  dataTestid: PropTypes.string,
  /**
   * (Optional) Error to show
   */
  children: PropTypes.node.isRequired,
};

const ImagePreviewContext = createContext();

function ImagePreviewProvider({ children }) {
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [images, setImages] = useState([]);

  const value = useMemo(
    () => ({
      showImagePreview,
      setShowImagePreview,
      images,
      setImages,
    }),
    [showImagePreview, setShowImagePreview, images, setImages]
  );

  return (
    <ImagePreviewContext.Provider value={value}>
      {children}
    </ImagePreviewContext.Provider>
  );
}

function useImagePreview() {
  const context = useContext(ImagePreviewContext);

  if (context === undefined) {
    throw new Error('useConcepts must be used within a ImagePreviewProvider');
  }
  return context;
}
ImagePreviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ImagePreviewDialog, ImagePreviewProvider, useImagePreview };
