/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { InfoDialog } from 'r/components';
import { useNavigate } from 'react-router-dom';

const GlobalErrorDialog = ({ onClose, open, error, isRecoverable }) => {
  const navigate = useNavigate();

  let handleClose = () => {
    onClose();
  };

  let headerText = (
    <>
      Oops, Something <span className="no-widow">Went Wrong</span>
    </>
  );

  let bodyText = (
    <>
      The application encountered an error and could not complete your request.
      Please try again later or{' '}
      <a href="mailto:support@treat.xyz">contact us</a> if the problem persists.
      Thanks for your patience!
    </>
  );

  let buttonText = isRecoverable ? 'Close' : 'Reload page';

  // temporary special case for 500 error on /creative-audit page
  // to show 'Waiting on data' message
  if (
    error?.response?.status === 404 ||
    (error?.response?.status === 500 &&
      window.location.pathname === '/creative-audit')
  ) {
    handleClose = () => {
      onClose();
      navigate('/integrations');
    };

    headerText = (
      <>
        We&#39;re Still Waiting <span className="no-widow">on Your Data</span>
      </>
    );

    bodyText = (
      <>
        If you haven&#39;t already, please visit our Integrations page <br />
        to connect Treat to your data.
      </>
    );

    buttonText = 'Go To Integrations';
  }

  return (
    <InfoDialog
      dataTestid="global-error-dialog"
      open={open}
      onClose={handleClose}
      headerText={headerText}
      bodyText={bodyText}
      sx={{ zIndex: 1600 }} // Above everything.
      buttonText={buttonText}
    />
  );
};

GlobalErrorDialog.defaultProps = {
  open: false,
  error: null,
};

GlobalErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number,
    }),
  }),
  isRecoverable: PropTypes.bool.isRequired,
};

export { GlobalErrorDialog };
