import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import Values from 'values.js';

const localOperatorData = [
  'equals',
  'less than',
  'greater than',
  'less than or equal to',
  'greater than or equal to',
];

function isRunningInStorybook() {
  try {
    if (process.env.STORYBOOK) return true;
  } catch {
    // A ReferenceError will be thrown if process is undefined
  }

  return false;
}

// Default rules for validation regex.
const defaultRules = [
  {
    data: 'url',
    regex:
      /^(https?:\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    message: 'Enter a valid URL',
  },
  {
    data: 'shop',
    regex: /^[0-9a-zA-Z-]+$/,
    message: 'Enter a valid Shopify store URL (e.g. your-store-name)',
  },
  {
    data: 'email',
    regex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
    message: 'Enter a valid email address',
  },
  {
    data: 'password',
    regex: /^.{6,}$/,
    message: 'Password length must be at least 6 characters',
  },
  {
    data: 'key',
    regex: /^[a-zA-Z0-9]+$/,
    message: 'Please enter a valid key.',
  },
];

/**
 * Validate an input using a regex pattern.
 *
 * @param   {string}  value          Input value.
 * @param   {string}  ruleType       What type of string is this regex matching?
 * @param   {string}  customMessage  Optional custom error message to use instead of default.
 *
 * @return  {bool|string}            True or error message string.
 */
function validateInput(value, ruleType, customMessage = '') {
  if (!value || !ruleType) {
    return null;
  }

  const defaults = defaultRules.filter((rule) => rule.data === ruleType);
  const { regex, message } = defaults[0];

  return regex.test(value) ? true : customMessage || message;
}

/*
 * error object, message prop can be a string, or an object shaped like:
 * {"errors":[{"field":"general","message":"Unauthorized."}]}
 * {"errors":[{"field":"general","message":{"errors":{"email":["has already been taken"]}}}]}
 * */
function setErrorsFromServer(errors = [], callback) {
  errors.forEach(({ field: _field, message }) => {
    if (typeof message === 'string') {
      callback(_field, message);
    } else {
      Object.keys(message?.errors).forEach((field) => {
        message.errors[field].forEach((msg) => {
          callback(field, msg);
        });
      });
    }
  });
}

function sliceFirstLetters(str) {
  return str
    .split(' ')
    .map((item) => item.slice(0, 1))
    .join('');
}

function recursiveSnakeCase(obj) {
  return transform(obj, (acc, value, key, target) => {
    const camelKey = Array.isArray(target) ? key : snakeCase(key);

    acc[camelKey] = isObject(value) ? recursiveSnakeCase(value) : value;
  });
}

function recursiveTransformKeys(obj, fn) {
  return transform(obj, (acc, value, key, target) => {
    const renamedKey = Array.isArray(target) ? key : fn(key);

    acc[renamedKey] = isObject(value)
      ? recursiveTransformKeys(value, fn)
      : value;
  });
}

/**
 * Lighten a CSS hex color value (tint)
 *
 * See: https://github.com/noeldelgado/values.js/blob/master/README.md
 *
 * @param   {string}  hex  String hex code of color to lighten.
 * @param   {int}     amt  Percent to lighten color, 0-100.
 *
 * @return  {string}  Hex code of lightened color.
 */
function lightenColor(hex, amt) {
  const color = new Values(hex);
  const lightColor = color.tint(amt).hexString();
  return lightColor;
}

/**
 * Is the current view a comparison view?
 *
 * @param   {string}  page  Name of the view, passed down from router.
 *
 * @return  {bool}
 */
function isCompareView(page) {
  return page === 'campaigns' || page === 'channels' || page === 'products';
}

/**
 * Set state of the Treat heart loader (see index.html)
 *
 * @param   {bool}  state  true to show the loader, false to hide it.
 */
function setTreatLoader(state) {
  if (state) {
    document.querySelector('#loader-wrapper')?.classList.remove('disabled');
  } else {
    document.querySelector('#loader-wrapper')?.classList.add('disabled');
  }
}

/**
 * Get width of text in px.
 *
 * @param {string} text String to measure
 * @param {string} font Name of font.
 * @returns number Pixel width of text.
 */
function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

export {
  localOperatorData,
  isRunningInStorybook,
  validateInput,
  setErrorsFromServer,
  sliceFirstLetters,
  recursiveSnakeCase,
  recursiveTransformKeys,
  lightenColor,
  isCompareView,
  setTreatLoader,
  getTextWidth,
};
