import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={forwardedRef}
    {...props}
  >
    <g opacity="0.6">
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M4.583 5v12M17.416 5v12M10.816 5v12M6.417 5v9.6M13.016 5v9.6M8.617 5v9.6M15.216 5v9.6"
      />
      <path
        fill={strokeColor}
        fillRule="evenodd"
        d="M3.67 0H1.5A1.5 1.5 0 0 0 0 1.5v3.388h1V1.5a.5.5 0 0 1 .5-.5h2.17V0Zm14.666 1V0H20.5A1.5 1.5 0 0 1 22 1.5v3.388h-1V1.5a.5.5 0 0 0-.5-.5h-2.164Zm0 20H20.5a.5.5 0 0 0 .5-.5v-3.39h1v3.39a1.5 1.5 0 0 1-1.5 1.5h-2.164v-1ZM1 17.11v3.39a.5.5 0 0 0 .5.5h2.17v1H1.5A1.5 1.5 0 0 1 0 20.5v-3.39h1Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Product = svgWrapper(SvgComponent, {
  width: '22px',
  height: '22px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Product };
