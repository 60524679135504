import {
  NewCustomersIcon,
  CampaignIcon,
  ChannelIcon,
  ProductIcon,
  AudienceIcon,
  CreativePerformanceIcon,
  LinkIcon,
  DownloadIcon,
} from 'r/components/icons';

const primaryNavItems = {
  '/performance': NewCustomersIcon,
  '/audiences': AudienceIcon,
  '/campaigns': CampaignIcon,
  '/channels': ChannelIcon,
  '/products': ProductIcon,
  '/creative-audit': CreativePerformanceIcon,
  '/integrations': LinkIcon,
  '/downloads': DownloadIcon,
};

export { primaryNavItems };
