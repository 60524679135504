import { node } from 'prop-types';
import React, { useContext, createContext } from 'react';
import { integrationsApi as api } from './integrationsApi';

const IntegrationsContext = createContext();

function IntegrationsProvider({ children }) {
  const [integrations, setIntegrations] = React.useState(null);
  const value = React.useMemo(
    () => ({
      api,
      integrations,
      setIntegrations,
    }),
    [integrations]
  );
  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
}

function useIntegrations() {
  const context = useContext(IntegrationsContext);

  if (context === undefined) {
    throw new Error(
      'useIntegrations must be used within an IntegrationsProvider'
    );
  }
  return context;
}

IntegrationsProvider.propTypes = {
  children: node.isRequired,
};

export { IntegrationsProvider, useIntegrations, IntegrationsContext };
