import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { uuidv4 } from 'r/theme/utils';

const parseNumeric = (values = []) => {
  const [val] = values;
  // take first non-nil, as we don't have between for numeric right now
  const extractedVal = ['lt', 'gt', 'lte', 'gte', 'eq'].find(
    (o) => !isNil(val[o])
  );
  switch (extractedVal) {
    case 'lt':
      return 'less than';
    case 'lte':
      return 'less than or equal to';
    case 'gt':
      return 'greater than';
    case 'gte':
      return 'greater than or equal to';
    case 'eq':
      return 'equals';
    default:
      return null;
  }
};

const translateFilterTypeToOperator = (ft) => {
  switch (ft) {
    case 'any':
      return 'is';
    case 'none':
      return 'is not';
    default:
      return 'is';
  }
};

const parseString = (filterTypes) => {
  const selected = filterTypes.find((f) => f.selected);
  return translateFilterTypeToOperator(selected?.type_key);
};

const parseOperator = (dataType, filterValues, filterTypes) => {
  let result;
  switch (dataType) {
    case 'number':
      result = parseNumeric(filterValues);
      break;
    case 'currency':
      result = parseNumeric(filterValues);
      break;
    case 'string':
      result = parseString(filterTypes);
      break;
    default:
      result = 'is';
      break;
  }
  return result;
};
const convertApiRangeFilterToUiState = (filter) =>
  omit(omitBy(filter, isNil), 'selected');

// range filters will contain an array of range objects which are all considered 'selected'
// string filters will be an array of objects with where some values are selected
const updateUiFilter = (af, f) => {
  const clonedUiFilter = cloneDeep(f);
  const toClone =
    af.data_type === 'string'
      ? af.filter_values
          .filter((fv) => fv.selected)
          .map((sfv) => sfv.filter_value)
      : [convertApiRangeFilterToUiState(af.filter_values[0])];

  clonedUiFilter.category = af.filter_key;
  clonedUiFilter.value = cloneDeep(toClone);
  clonedUiFilter.operator = parseOperator(
    af.data_type,
    af.filter_values,
    af.filter_types
  );
  return clonedUiFilter;
};
const convertApiStringFilterToUiState = (filter) => filter?.filter_value;
const convertApiFilterValueToUiState = (apiFilter) =>
  apiFilter.filter_values
    .filter((f) => f.selected)
    .map((fv) => {
      return apiFilter.data_type === 'string'
        ? convertApiStringFilterToUiState(fv)
        : convertApiRangeFilterToUiState(fv);
    });

const convertApiFiltersToUiState = (filtersFromApi = []) =>
  filtersFromApi
    .filter((f) => f.applied)
    .map((sf) => ({
      category: sf.filter_key,
      index: uuidv4(),
      operator: parseOperator(sf.data_type, sf.filter_values, sf.filter_types),
      value: convertApiFilterValueToUiState(sf),
    }));

const syncApiFiltersToUi = (filtersFromApi = [], uiFilterState = []) => {
  const appliedFilters = filtersFromApi.filter((f) => f.applied);

  // no filters from API
  if (appliedFilters.length === 0) {
    return [];
  }

  // when no selected filters, take what the API returns
  if (uiFilterState.length === 0) {
    return convertApiFiltersToUiState(appliedFilters);
  }

  // same length, walk through the applied filters from API sync value to UI state
  if (appliedFilters.length === uiFilterState.length) {
    /* eslint-disable no-param-reassign */
    const uiSortMap = uiFilterState.reduce(
      (memo, currentFilter, arrayIndex) => {
        memo[currentFilter.index] = arrayIndex;
        return memo;
      },
      {}
    );
    /* eslint-disable no-param-reassign */
    const updatedUiFilters = [];

    // track updated filters here
    const uiFilterIndexMap = {};

    // First we filter out filters we successful found matches,
    // then deal with what's left over
    const noMatch = appliedFilters.filter((af) => {
      // format the values from API so we can check if it already exists in UI state
      const valueForComparison = convertApiFilterValueToUiState(af);
      const matching = uiFilterState.find((uif) => {
        if (uif.category !== af.filter_key) {
          return false;
        }
        // no match if different lengths
        if (valueForComparison.length !== uif.value.length) {
          return false;
        }
        const equal = isEqual(valueForComparison.sort(), uif.value.sort());
        // if there is a filter in UI state w/ matching value, use that
        if (equal) {
          uiFilterIndexMap[uif.index] = true;
          return true;
        }
        return false;
      });

      // keep the non-match for later
      if (!matching) {
        return true;
      }

      const update = updateUiFilter(af, matching);
      updatedUiFilters.push(update);
      return false;
    });
    if (noMatch.length > 0) {
      // find the uiFilter which has not been updated
      const uifToUpdate = uiFilterState.find((f) => {
        if (!uiFilterIndexMap[f.index]) {
          return true;
        }
        return false;
      });
      // sync it with the remaining item in noMatches
      const update = updateUiFilter(noMatch[0], uifToUpdate);
      updatedUiFilters.push(update);
    }
    return updatedUiFilters.sort(
      (a, b) => uiSortMap[a.index] - uiSortMap[b.index]
    );
  }

  // if API returns a filter not in UI state, append it
  if (appliedFilters.length > uiFilterState.length) {
    const apiFiltersToAppend = appliedFilters.filter((filter) => {
      // skip order_date because it cannot be added/removed
      if (filter.filter_key === 'order_date') {
        return false;
      }
      // 1. if the UI state DOES have filters of current category, we check the values
      const exists = uiFilterState.some((uiFilter) => {
        if (uiFilter.category !== filter.filter_key) {
          return false;
        }
        // format the values from API so we can check if it already exists in UI state
        const valueForComparison = convertApiFilterValueToUiState(filter);
        return isEqual(valueForComparison.sort(), uiFilter.value.sort());
      });
      // 2. if the value exists in UI state, we ignore it
      if (exists) {
        return false;
      }
      // 3. if the value DOES NOT exist in UI state, we append it
      return true;
    });
    const converted = convertApiFiltersToUiState(apiFiltersToAppend);
    return uiFilterState.concat(converted);
  }

  // if API returns fewer filters than in UI state, remove
  if (appliedFilters.length < uiFilterState.length) {
    // keep the filters if the category and value match
    return uiFilterState.filter((uiFilter) => {
      // skip order_date because it cannot be added/removed
      if (uiFilter.category === 'order_date') {
        return true;
      }
      // 1. if the UI state DOES have filters of current category, we check the values
      const exists = appliedFilters.some((apiFilter) => {
        if (apiFilter.filter_key !== uiFilter.category) {
          return false;
        }
        // format the values from API so we can check if it already exists in UI state
        const valueForComparison = convertApiFilterValueToUiState(apiFilter);
        return isEqual(valueForComparison.sort(), uiFilter.value.sort());
      });
      // 2. if the filter and value were returned from the API, we ignore it
      if (exists) {
        return true;
      }
      // 3. if the value DOES NOT exist in UI state, we need to remove it
      return false;
    });
  }

  return [];
};

const recalcFilterOptions = (data, uiFilterState = []) => {
  const operatorOptions = ['is'];
  // quick lookup for each field and its data type
  const dataTypeMap = {};

  // quick lookup for each field and available operators
  const fieldOperatorMap = {};

  const fieldOptions = [];

  (data?.filters ?? []).forEach((field) => {
    // use the dataTypeMap to determine if we've already seen this filter
    const isDuplicate = dataTypeMap[field.filter_key];

    // When multiple filters applied, filter will be present twice
    // But we only want to show the filter option once in the category menu
    if (!isDuplicate) {
      fieldOptions.push({
        label: field.name,
        value: field.filter_key,
        group: field.group_name,
      });

      fieldOperatorMap[field.filter_key] = field.filter_types.map((ft) =>
        translateFilterTypeToOperator(ft.type_key)
      );

      dataTypeMap[field.filter_key] = field.data_type;
    }
  });

  const allValueOptions = (data?.filters ?? []).reduce((memo, curr) => {
    /* eslint-disable-next-line no-param-reassign */
    memo[curr.filter_key] = {
      options: curr.filter_values.map((f) => ({
        label: f.name,
        value: f.filter_value,
      })),
      multiple: true,
    };
    return memo;
  }, {});

  const selectedFilters = syncApiFiltersToUi(
    data?.filters ?? [],
    uiFilterState
  );

  const nextState = {
    fieldOptions,
    operatorOptions,
    allValueOptions,
    selectedFilters,
    dataTypeMap,
    fieldOperatorMap,
  };

  return nextState;
};

export {
  recalcFilterOptions,
  translateFilterTypeToOperator,
  syncApiFiltersToUi,
  parseOperator,
  parseString,
  parseNumeric,
  convertApiRangeFilterToUiState,
  updateUiFilter,
  convertApiFilterValueToUiState,
  convertApiFiltersToUiState,
};
