import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box, Select, MenuItem, FormControl } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

import { useAuth } from 'r/context/auth';
import { ArrowDown } from 'r/components/icons';
import { trtClientApiAuthCheck } from 'r/services';
import { sidebarSx } from './sidebarSx';

const WorkspaceDropdown = ({ open, ...props }) => {
  const theme = useTheme();
  const selectBoxRef = useRef(null);
  const { selectSx, selectMenuProps } = sidebarSx(theme, open);
  const { user } = useAuth();
  const location = useLocation();

  if (!user || !user.accounts || user.accounts.length <= 1) return null;

  const handleChange = async (event) => {
    const accountId = event.target.value;
    await trtClientApiAuthCheck.post('/auth/switch_accounts', {
      account_id: accountId,
    });

    const { pathname } = location;
    window.location.href = pathname;
  };

  return (
    <Box sx={{ mb: 4, mt: 5 }} {...props}>
      <FormControl sx={{ width: '100%' }}>
        <Box id="workspace-select-label" component="span" sx={visuallyHidden}>
          Select Workspace
        </Box>
        <Select
          ref={selectBoxRef}
          IconComponent={ArrowDown}
          id="workspace-select"
          labelId="workspace-select-label"
          fullWidth
          variant="outlined"
          sx={selectSx}
          MenuProps={{
            id: 'workspace-menu',
            className: 'workspace-menu',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              zIndex: 1302, // Higher than sidebar bg.
            },
            ...selectMenuProps,
          }}
          defaultValue={user.current_account?.account_id}
          value={user.current_account?.account_id}
          onClose={() => {
            selectBoxRef.current.classList.remove('Mui-focused');
          }}
          onOpen={() => {
            selectBoxRef.current.classList.add('Mui-focused');
          }}
          onChange={handleChange}
        >
          {user.accounts.map((account) => {
            return (
              <MenuItem key={account.account_id} value={account.account_id}>
                {account.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

WorkspaceDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
};

export { WorkspaceDropdown };
