import { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useConcepts } from 'r/context/concepts';
import { useSearchParams } from 'react-router-dom';
import { useIsMounted } from 'r/hooks/useIsMounted';

// This component handles initialization from URL params (SID).
// It DOES NOT handle changes to filters
function ConceptsContainer({ children }) {
  const { initializePage, concepts, loading } = useConcepts();
  const [searchParams] = useSearchParams();
  const isMounted = useIsMounted();

  const sid = searchParams.get('sid');
  const page = searchParams.get('page') ?? 1;
  const sortDirection = searchParams.get('sortDirection');
  const sortColumn = searchParams.get('sortColumn');

  const countRef = useRef(0);
  // this block handles initial page load scenarios,
  useEffect(() => {
    if (!isMounted.current || countRef.current > 0) {
      return;
    }

    if (sid !== concepts.meta.sid) {
      initializePage({
        page: parseInt(page, 10),
        sid,
        sortDirection,
        sortColumn,
      });
    }
    countRef.current = 1;
  }, [
    concepts.meta.sid,
    initializePage,
    page,
    sid,
    sortDirection,
    sortColumn,
    loading,
    isMounted,
  ]);

  return children;
}

ConceptsContainer.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
    propTypes.func,
  ]).isRequired,
};

export { ConceptsContainer };
