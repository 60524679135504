import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';
import { SidebarNavItem } from './SidebarNavItem';

const NavItems = ({ navItems, open, itemSx = {} }) => {
  return (
    <List className="nav-list" sx={{ '& li': { marginBottom: 0.2 } }}>
      {navItems.map(({ label: text, icon, linkTo, onClick, disabled }) => (
        <SidebarNavItem
          ItemIcon={icon}
          itemText={text}
          itemLink={linkTo}
          open={open}
          onItemClick={() => {
            if (onClick && typeof onClick === 'function') onClick();
          }}
          key={text}
          sx={itemSx}
          disabled={disabled}
        />
      ))}
    </List>
  );
};

NavItems.defaultProps = {
  open: false,
  itemSx: {},
};

NavItems.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.shape({}).isRequired,
      linkTo: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  open: PropTypes.bool,
  itemSx: PropTypes.shape({}),
};

export { NavItems };
