import { trtClientApi } from 'r/services';

const dashboardApi = (accountId, payload = {}, options = {}) => {
  return trtClientApi.post(`/search/${accountId}/init`, payload, options);
};

const dashboardTilesApi = (accountId, payload = {}, options = {}) => {
  return trtClientApi.post(`/search/${accountId}`, payload, options);
};

const dashboardDrilldownApi = (accountId, payload = {}, options = {}) => {
  return trtClientApi.post(`/search/${accountId}/drilldown`, payload, options);
};

const dashboardPerformanceApi = (
  accountId,
  type,
  payload = {},
  options = {}
) => {
  return trtClientApi.post(
    `/performance/${accountId}/${type}`,
    payload,
    options
  );
};

export {
  dashboardApi,
  dashboardTilesApi,
  dashboardDrilldownApi,
  dashboardPerformanceApi,
};
