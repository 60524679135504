import { Stack, Skeleton } from '@mui/material';
import { bool, string } from 'prop-types';
import { PageTitle } from 'r/components/PageTitle';

export const PageLazyLoadSkeleton = ({ title, fullHeight }) => (
  <Stack
    sx={{
      px: '32px',
      pt: 5,
      pb: 4,
      background: 'white',
      minHeight: fullHeight ? '100vh' : '132px',
    }}
  >
    <PageTitle>{title}</PageTitle>
    {fullHeight ? (
      <Skeleton variant="text" height={18} width={300} />
    ) : (
      <Skeleton
        variant="rounded"
        height={30}
        width={114}
        sx={{ marginTop: 'auto' }}
      />
    )}
  </Stack>
);

PageLazyLoadSkeleton.defaultProps = {
  fullHeight: false,
};

PageLazyLoadSkeleton.propTypes = {
  title: string.isRequired,
  fullHeight: bool,
};
