import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.6">
      <path
        d="M7.55271 10.0017C7.07134 9.36009 6.8352 8.56769 6.88687 7.76741C6.93855 6.96713 7.27464 6.21165 7.83452 5.63726L11.4354 1.97186C11.7353 1.66428 12.0937 1.41982 12.4895 1.25291C12.8854 1.08599 13.3107 1 13.7403 1C14.17 1 14.5953 1.08599 14.9911 1.25291C15.387 1.41982 15.7454 1.66428 16.0453 1.97186V1.97186C16.6575 2.59929 17.0002 3.44104 17.0002 4.31751C17.0002 5.19397 16.6575 6.03573 16.0453 6.66315L13.6377 9.26788"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.83735 10.3288C7.73648 10.2261 7.64239 10.1169 7.55566 10.002"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.0439 7.7998C10.652 8.42659 10.9921 9.26539 10.9921 10.1385C10.9921 11.0116 10.652 11.8504 10.0439 12.4772L6.56479 16.0278C6.26495 16.3354 5.90653 16.5799 5.51067 16.7468C5.1148 16.9137 4.68951 16.9997 4.25986 16.9997C3.83022 16.9997 3.40493 16.9137 3.00906 16.7468C2.6132 16.5799 2.25478 16.3354 1.95494 16.0278V16.0278C1.34269 15.4004 1 14.5586 1 13.6822C1 12.8057 1.34269 11.964 1.95494 11.3365L4.27204 8.90221"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const Link = svgWrapper(SvgComponent, {
  width: '18px',
  height: '18px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Link };
