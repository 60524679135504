import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    viewBox="0 0 22 12"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="m1 11 5.79-5.714 6.315 4.081L21 1m0 0h-5m5 0v5.5"
      opacity="0.6"
    />
  </svg>
);

const NewCustomersIcon = svgWrapper(SvgComponent, {
  width: '40px',
  height: '22px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { NewCustomersIcon };
