import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 14"
    fill="none"
    ref={forwardedRef}
    {...props}
  >
    <circle
      cx="11.465"
      cy="4.566"
      r="2.317"
      stroke={strokeColor}
      opacity="0.6"
    />
    <circle cx="4.367" cy="2.459" r="1.96" stroke={strokeColor} opacity="0.6" />
    <g opacity="0.6">
      <circle cx="18.636" cy="2.459" r="1.96" stroke={strokeColor} />
      <path
        fill={strokeColor}
        fillRule="evenodd"
        d="M4.365 6.64C2.517 6.64 1 8.175 1 10.089a.5.5 0 1 1-1 0C0 7.642 1.945 5.64 4.365 5.64c2.062 0 3.778 1.451 4.242 3.393a4.838 4.838 0 0 1 2.858-.93 4.84 4.84 0 0 1 2.919.975c.448-1.964 2.174-3.438 4.252-3.438 2.42 0 4.365 2 4.365 4.447a.5.5 0 1 1-1 0c0-1.914-1.517-3.447-3.365-3.447-1.801 0-3.286 1.455-3.362 3.299a5.062 5.062 0 0 1 1.117 3.184.5.5 0 1 1-1 0c0-2.23-1.767-4.02-3.926-4.02-1.079 0-2.06.447-2.772 1.173a.502.502 0 0 1-.226.251 4.058 4.058 0 0 0-.928 2.596.5.5 0 1 1-1 0c0-1.244.445-2.386 1.184-3.265-.115-1.806-1.583-3.218-3.358-3.218Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Audience = svgWrapper(SvgComponent, {
  width: '25px',
  height: '14px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Audience };
