import React from 'react';
import { node } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'r/context/auth';
import { trtClientApi } from 'r/services';

const checkActiveLink = ({ isActive }) => {
  return {
    color: isActive ? 'red' : '',
  };
};

const Logout = ({ children }) => {
  const onClick = async () => {
    await trtClientApi.get('/auth/logout');
    // replace with React Router way
    window.location.href = '/login';
  };

  React.useEffect(() => {
    onClick();
  }, []);

  return (
    <span onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      {children}
    </span>
  );
};

export default () => {
  const { user } = useAuth();

  return user ? null : (
    <>
      <h3>
        <NavLink style={checkActiveLink} to="/login">
          Login
        </NavLink>
      </h3>
      <h3>
        <NavLink style={checkActiveLink} to="/signup">
          Signup
        </NavLink>
      </h3>
    </>
  );
};

Logout.propTypes = {
  children: node.isRequired,
};

export { Logout };
