import { node } from 'prop-types';
import { uuidv4 } from 'r/theme/utils';
import {
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { subDays, startOfDay, format } from 'date-fns';
import { useIsMounted } from 'r/hooks/useIsMounted';
import { useGlobalError } from 'r/context/globalError';
import { recalcFilterOptions } from './recalcFilterOptions';
import { dashboardApi } from './dashboardApi';
import { useAuth } from '../auth';
import {
  transformFilterStateToApiFormat,
  transformStateForApiRequest,
} from './transformStateForApiRequest';

const DATE_PARSE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_PERIOD_AS_DAYS_BEFORE_TODAY = 30;
const defaultOrderDate = () =>
  format(
    startOfDay(subDays(new Date(), DEFAULT_TIME_PERIOD_AS_DAYS_BEFORE_TODAY)),
    DATE_PARSE_FORMAT
  );

const DashboardContext = createContext();

const initialState = {
  page: null,
  available_tiles: [],
  available_metrics: [],
  tiles: [],
  filters: [],
  available_comparands: [],
  comparands: [],

  // ui state for linear filter
  fieldOptions: [],
  operatorOptions: [],
  allValueOptions: {},
  selectedFilters: [],
  dataTypeMap: {},
  fieldOperatorMap: {},
};

function DashboardProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setErrorToShow } = useGlobalError();

  // Tracks how many filter states are available in browser history
  const [filterHistoryCount, setFilterHistoryCount] = useState(0);
  // Tracks which of the filter states available in browser history is currently being used
  // filterUndoIndex === 0  ->  oldest
  // filterUndoIndex === filterUndoCount  ->  newest
  const [filterHistoryIndex, setFilterHistoryIndex] = useState(0);

  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState(initialState);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const accountId = user?.current_account_id;

  const isMounted = useIsMounted();

  // called when API responds, recalc data for UI
  const updateDashboardStateFromApiResponse = useCallback(
    (data, selectedFilters) => {
      const filters = data.filters
        .filter((f) => f.applied)
        .map((f) => {
          return {
            filter_key: f.filter_key,
            filter_types: [f.filter_types.find((ft) => ft.selected)?.type_key],
            filter_values: f.filter_values.filter((fv) => fv.selected),
          };
        });

      const filterData = recalcFilterOptions(data, selectedFilters);

      const nextState = {
        ...data,
        filters,
        ...filterData,
      };

      setDashboard((prevState) => {
        return {
          ...prevState,
          ...nextState,
        };
      });
    },
    []
  );

  const updateDashboard = useCallback(
    async (update) => {
      // merge old state w/ updated piece of state
      const { page, filters } = {
        ...transformStateForApiRequest(dashboard),
        ...update,
      };

      setLoading(true);
      const response = await dashboardApi(accountId, {
        page,
        filters,
      }).catch((error) => setErrorToShow(error));

      if (response?.data && isMounted.current) {
        updateDashboardStateFromApiResponse(
          { page, ...response.data },
          dashboard.selectedFilters
        );
        const sid = searchParams.get('sid');

        // don't update if it's already in the URL (it will be, for back/forward nav)
        // as that will cause a double entry in browser history
        if (response.data.sid && response.data.sid !== sid) {
          setSearchParams({ sid: response.data.sid });
        }

        // Add new filter state to the history counter
        setFilterHistoryCount(filterHistoryIndex + 1);
        setFilterHistoryIndex(filterHistoryIndex + 1);

        setLoading(false);
      }
    },
    [
      dashboard,
      accountId,
      isMounted,
      setErrorToShow,
      updateDashboardStateFromApiResponse,
      searchParams,
      filterHistoryIndex,
      setSearchParams,
    ]
  );

  const initializeDashboard = useCallback(
    async (
      { page, sid, campaign, channel, dateFilter, source },
      resetFilters = false
    ) => {
      setLoading(true);
      const dateFilterKey =
        page.toLowerCase() === 'performance'
          ? 'first_order_date'
          : 'order_date';
      const response = await dashboardApi(accountId, {
        page,
        sid,
        filters: [
          {
            filter_key: dateFilterKey,
            filter_values: [dateFilter || { gte: defaultOrderDate() }],
            filter_types: ['any'],
          },
          campaign && {
            filter_key: 'first_purchase_utm_campaign',
            filter_types: ['any'],
            filter_values: [campaign],
          },
          channel && {
            filter_key: 'first_purchase_channel',
            filter_types: ['any'],
            filter_values: [channel],
          },
          source && {
            filter_key: 'first_purchase_utm_source',
            filter_values: [source],
            filter_types: ['any'],
          },
        ].filter(Boolean),
      }).catch((error) => setErrorToShow(error));

      if (response?.data && isMounted.current) {
        updateDashboardStateFromApiResponse(
          { page, ...response.data },
          resetFilters ? [] : dashboard.selectedFilters
        );

        const sidParam = searchParams.get('sid');
        // don't update if it's already in the URL (it will be, for back/forward nav)
        // as that will cause a double entry in browser history
        if (response.data.sid && response.data.sid !== sidParam) {
          setSearchParams({ sid: response.data.sid });
        }

        setLoading(false);
      }
    },

    [
      accountId,
      isMounted,
      setErrorToShow,
      updateDashboardStateFromApiResponse,
      dashboard.selectedFilters,
      searchParams,
      setSearchParams,
    ]
  );

  const updateFilter = useCallback(
    (filterUIState) => {
      const update = {
        filters: transformFilterStateToApiFormat(filterUIState),
      };
      updateDashboard(update);
    },
    [updateDashboard]
  );

  // Allow passing a partial list of raw filters to
  // be appended to filters already in state
  const appendRawFilters = useCallback(
    (rawFilterData) => {
      const selectedFiltersFromState = transformFilterStateToApiFormat(
        dashboard.selectedFilters
      );

      const allSelectedFilters = [
        ...rawFilterData,
        ...selectedFiltersFromState,
      ];
      updateDashboard({ filters: allSelectedFilters });
    },
    [updateDashboard, dashboard]
  );

  const undoFilterChange = useMemo(() => {
    // We can undo if we aren't curently using the oldest possible state
    const canUndo = filterHistoryIndex > 0;
    return canUndo
      ? () => {
          setFilterHistoryIndex(filterHistoryIndex - 1);
          navigate(-1);
        }
      : null;
  }, [filterHistoryIndex, navigate]);

  const redoFilterChange = useMemo(() => {
    // We can redo if we aren't curently using the newest possible state
    const canRedo = filterHistoryIndex < filterHistoryCount;
    return canRedo
      ? () => {
          setFilterHistoryIndex(filterHistoryIndex + 1);
          navigate(+1);
        }
      : null;
  }, [filterHistoryCount, filterHistoryIndex, navigate]);

  const updateOrderDateFilter = useCallback(
    (value) => {
      const filterKey =
        dashboard.page.toLowerCase() === 'performance'
          ? 'first_order_date'
          : 'order_date';
      const orderDate = dashboard.selectedFilters.find(
        (f) => f.category === filterKey
      );

      if (orderDate) {
        orderDate.value = value;
      } else {
        const filter = {
          category: filterKey,
          index: uuidv4(),
          operator: 'is',
          value,
        };
        dashboard.selectedFilters.push(filter);
      }

      const update = {
        filters: transformFilterStateToApiFormat(dashboard.selectedFilters),
      };
      updateDashboard(update);
    },
    [updateDashboard, dashboard.selectedFilters, dashboard.page]
  );

  const value = useMemo(
    () => ({
      dashboard,
      updateFilter,
      appendRawFilters,
      initializeDashboard,
      loading,
      undoFilterChange,
      redoFilterChange,
      updateOrderDateFilter,
    }),
    [
      dashboard,
      updateFilter,
      appendRawFilters,
      initializeDashboard,
      loading,
      undoFilterChange,
      redoFilterChange,
      updateOrderDateFilter,
    ]
  );
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

function useDashboard() {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error('useDashboard must be used within an DashboardProvider');
  }
  return context;
}

DashboardProvider.propTypes = {
  children: node.isRequired,
};

export { DashboardProvider, useDashboard, DashboardContext, defaultOrderDate };
