import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, strokeWidth, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 0.5L6 5.5L11 0.5"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

const ArrowDown = svgWrapper(SvgComponent, {
  width: '12px',
  height: '6px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
  strokeWidth: '1',
};

SvgComponent.propTypes = {
  strokeColor: string,
  strokeWidth: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { ArrowDown };
