/* eslint-disable no-console */
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as FullStory from '@fullstory/browser';
import App from './App';
import { setLicenseKey } from './MuiXLicense';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://afab55c657c445998e9f192f8a76f0b5@o944617.ingest.sentry.io/6731875',
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.FULLSTORY_ORG_ID) {
  FullStory.init(
    { orgId: process.env.FULLSTORY_ORG_ID, debug: true },
    ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
  );
}

if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MOCK_BACKEND) {
  /* eslint-disable-next-line */
  const { worker } = require('./mocks/browser');
  worker.start();
}

setLicenseKey();

createRoot(document.getElementById('app')).render(<App />);
