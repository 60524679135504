/* eslint-disable react/no-unused-state */
import { node } from 'prop-types';
import React, { useContext, createContext } from 'react';
import * as Sentry from '@sentry/react';
import { GlobalErrorDialog } from 'r/components';

const GlobalErrorContext = createContext();

class GlobalErrorProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorToShow: null,
      isRecoverable: true,
      setErrorToShow: (error, isRecoverable = true) => {
        this.setState({ errorToShow: error, isRecoverable });
        Sentry.captureException(error);
      },
    };
  }

  componentDidCatch(error) {
    const { setErrorToShow } = this.state;
    setErrorToShow(error, false);
  }

  clearError = () => {
    const { isRecoverable } = this.state;
    if (isRecoverable) {
      this.setState({ errorToShow: null, isRecoverable: true });
    } else {
      window.location.href = window.location.pathname;
    }
  };

  render() {
    const { children } = this.props;
    const { errorToShow, isRecoverable } = this.state;

    return (
      <GlobalErrorContext.Provider value={this.state}>
        {isRecoverable && children}
        {errorToShow && (
          <GlobalErrorDialog
            open
            onClose={this.clearError}
            error={errorToShow}
            isRecoverable={isRecoverable}
          />
        )}
      </GlobalErrorContext.Provider>
    );
  }
}

function useGlobalError() {
  const context = useContext(GlobalErrorContext);

  if (context === undefined) {
    throw new Error(
      'useGlobalError must be used within an GlobalErrorProvider'
    );
  }
  return context;
}

GlobalErrorProvider.propTypes = {
  children: node.isRequired,
};

export { GlobalErrorProvider, useGlobalError, GlobalErrorContext };
