const transformFilterStateToApiFormat = (filters = []) => {
  // removes some props before sending to API
  const result = filters.map(
    ({ index, data_type: dataType, applied, ...sf }) => {
      return {
        ...sf,
        filter_types: ['any'],
        filter_values: sf.filter_values.map((fv) => {
          return typeof fv === 'string' ? fv : fv?.filter_value;
        }),
      };
    }
  );
  return result;
};

const selectedFilters = (state) => {
  return transformFilterStateToApiFormat(state.meta.applied_filters);
};

const selectedPage = (state) => {
  return state.meta.current_page;
};

const selectedSortDirection = (state) => state.meta.sort_direction;

const selectedSortColumn = (state) => state.meta.sort_column;

// we are always merging this with an update, so we don't need to calculate here
const transformStateForApiRequest = (state) => {
  return {
    filters: selectedFilters(state),
    page: selectedPage(state),
    sortDirection: selectedSortDirection(state),
    sortColumn: selectedSortColumn(state),
  };
};

export { transformStateForApiRequest, transformFilterStateToApiFormat };
