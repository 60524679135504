import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton as MuiButton } from '@mui/lab';

const buttonSx = {
  '&.MuiLoadingButton-loading': {
    '&.MuiButton-containedPrimary': {
      backgroundColor: 'primary.main',
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: 'secondary.main',
    },
    '&.MuiButton-containedSuccess': {
      backgroundColor: 'success.main',
    },
    '&.MuiButton-containedError': {
      backgroundColor: 'error.main',
    },
    '&.MuiButton-contained': {
      svg: {
        color: 'primary.contrastText',
      },
    },
    '&.MuiButton-brandEmailPrimary': {
      svg: {
        color: 'primary.contrastText',
      },
    },
    '&.MuiButton-sizeLarge': {
      '[role=progressbar]': {
        width: '24px !important',
        height: '24px !important',
      },
    },
    '&.MuiButton-sizeMedium': {
      '[role=progressbar]': {
        width: '20px !important',
        height: '20px !important',
      },
    },
  },
};

const Button = React.forwardRef(
  ({ children, variant, size, loading, sx, ...props }, ref) => {
    if (!children || !variant || !size) {
      return null;
    }

    return (
      <MuiButton
        sx={{ ...buttonSx, ...sx }}
        variant={variant}
        size={size}
        ref={ref}
        loading={loading}
        {...props}
      >
        {children}
      </MuiButton>
    );
  }
);

Button.defaultProps = {
  loading: false,
  sx: null,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export { Button };
