import { trtClientApi } from 'r/services';

const publicBriefsApi = {
  fetchAll: async ({ accountId }) => {
    const uri = `/brief/${accountId}`;
    const response = await trtClientApi.get(uri);
    return response;
  },
  downloadImage: async ({ accountId, briefId, imageId }) => {
    const uri = `/brief/${accountId}/${briefId}/${imageId}`;
    const response = await trtClientApi.get(uri);
    return response;
  },
};

export { publicBriefsApi };
