import { useState, useEffect } from 'react';
import { trtClientApiAuthCheck } from 'r/services';
import { setTreatLoader } from 'r/services/utils';

// Context.
import { useAuth } from 'r/context/auth';

export default ({ children }) => {
  const [didFetchUser, setDidFetchUser] = useState(null);
  const { setUser } = useAuth();

  useEffect(() => {
    if (didFetchUser) {
      return;
    }

    const initializeSession = async () => {
      try {
        const response = await trtClientApiAuthCheck.get('/auth/me');
        setUser(response.data.user);
      } catch (error) {
        console.log('>> error', error); // eslint-disable-line no-console
      } finally {
        setTreatLoader(false);
        setDidFetchUser(true);
      }
    };
    initializeSession();
  }, [didFetchUser, setUser]);

  return !didFetchUser ? null : children;
};
