import * as React from 'react';
import { useAuth } from 'r/context/auth';
import { AvatarBase } from './AvatarBase';

const Avatar = (props) => {
  const { user } = useAuth();

  return (
    <AvatarBase
      firstName={user?.first_name}
      companyName={user?.current_account?.name}
      {...props}
    />
  );
};

export { Avatar };
