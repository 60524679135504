import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

const headingFontFamily = ['Poppins', 'Arial', 'sans-serif'].join(',');
const bodyFontFamily = ['Inter', 'Arial', 'sans-serif'].join(',');
const emailFontFamily = 'Helvetica, Inter, arial, sans-serif';

const typeStyles = {
  emailBase: {
    fontFamily: emailFontFamily,
    fontWeight: 400,
  },
  typeDefaults: {
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  displayXXLargeBase: {
    fontFamily: headingFontFamily,
    fontSize: '6rem',
    fontWeight: 900,
    lineHeight: 1.3,
    margin: '0 0 20px',
  },
  displayXLargeBase: {
    fontFamily: headingFontFamily,
    fontSize: '3.6rem',
    fontWeight: 900,
    lineHeight: 1.3,
    margin: '0 0 20px',
  },
  displayLargeBase: {
    fontFamily: headingFontFamily,
    fontSize: '2.6rem',
    fontWeight: 900,
    lineHeight: 1.4,
    margin: '0 0 20px',
  },
  displayMediumBase: {
    fontFamily: headingFontFamily,
    fontSize: '2.3rem',
    fontWeight: 700,
    lineHeight: 1.4,
    margin: '0 0 20px',
  },
  displaySmallBase: {
    fontFamily: headingFontFamily,
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1.5,
    margin: '0 0 20px',
  },
  bodyBase: {
    fontFamily: bodyFontFamily,
    fontSize: '1.4rem',
    lineHeight: 1.6,
    letterSpacing: 'unset',
  },
  headingBase: {
    fontFamily: headingFontFamily,
    fontSize: '1.7rem',
    fontWeight: 600,
    lineHeight: 1.6,
  },
  subheadingBase: {
    fontFamily: bodyFontFamily,
    fontSize: '1.3rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
    lineHeight: 1.8,
    textTransform: 'uppercase',
  },
  captionBase: {
    fontSize: '1.2rem',
    lineHeight: 1.6,
    fontWeight: 500,
  },
};

export { headingFontFamily, bodyFontFamily, typeStyles };
