/* eslint-disable */
/* NOTE: refactor this file to pass eslint and for readability -MM */
import get from 'lodash/get';

function isObject(value) {
  return value && typeof value === 'object';
}

function parseTheme(theme) {
  const globalTheme = theme;
  const allowedKeys = ['components', 'typography', 'mixins'];

  if (!(isObject(theme) && Object.keys(theme).length)) {
    return;
  }

  function parser(themeProps, keys) {
    // eslint-disable-next-line no-restricted-syntax -- Note: this should be refactored. MM
    for (const key in themeProps) {
      if (keys && !keys.includes(key)) {
        continue; // eslint-disable-line no-continue
      }
      const current = themeProps[key];

      if (isObject(current)) {
        parser(themeProps[key]);
      }
      if (typeof current !== 'string') {
        continue; // eslint-disable-line no-continue
      }

      const colorMatches = current.match(/(palette\.(.*?))(\s|$)/i);
      if (colorMatches?.[1]) {
        const matched = colorMatches[1];
        const color = get(globalTheme, matched, matched);
        themeProps[key] = current.replace(new RegExp(matched, 'ig'), color); // eslint-disable-line no-param-reassign
        continue; // eslint-disable-line no-continue
      }
    }
  }

  parser(theme, allowedKeys);
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export { parseTheme, uuidv4 };
/* eslint-enable */
