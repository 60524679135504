import { CssBaseline, GlobalStyles } from '@mui/material';
import { globalCss } from 'r/theme/globalCss';

const TreatGlobalStyles = () => {
  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={globalCss} />
    </>
  );
};

export { TreatGlobalStyles };
