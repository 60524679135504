import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <g opacity="0.6" clipPath="url(#clip0_11658_174759)">
      <path
        d="M10.728 13.2329L17.6084 5.0332"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M9.95641 14.1524C10.454 14.5699 11.1957 14.505 11.6132 14.0074C12.0307 13.5099 11.9658 12.7681 11.4683 12.3506C10.9707 11.9332 10.2289 11.9981 9.81146 12.4956C9.39398 12.9931 9.45888 13.7349 9.95641 14.1524Z"
        fill="white"
      />
      <path
        d="M14.679 16.9249C16.5996 14.636 17.8779 4.71178 17.8779 4.71178C17.8779 4.71178 8.32637 7.69388 6.40572 9.98282C4.97873 11.6834 4.78957 13.9998 5.71124 15.8697L3.75202 18.2046L7.04295 20.966L9.00217 18.6311C10.996 19.2233 13.252 18.6255 14.679 16.9249Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_11658_174759">
        <rect
          width="12"
          height="21.12"
          fill="white"
          transform="translate(13.999) rotate(40)"
        />
      </clipPath>
    </defs>
  </svg>
);

const CreativePerformanceIcon = svgWrapper(SvgComponent, {
  width: '40px',
  height: '22px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { CreativePerformanceIcon };
