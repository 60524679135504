import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    width="23"
    height="17"
    fill="none"
    viewBox="0 0 23 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.6" clipPath="url(#clip0_9138_113412)">
      <path
        stroke={strokeColor}
        strokeLinejoin="round"
        d="M13.0001 1L1.34808 5.24567C1.31721 5.25692 1.29165 5.2793 1.27643 5.30843C0.476866 6.83795 0.476866 8.66205 1.27643 10.1916C1.29165 10.2207 1.31721 10.2431 1.34808 10.2543L13.0001 14.5"
      />
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M19.1035 8.07031H21.9996"
      />
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M19.1035 5.39828L21.1514 3.26514"
      />
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M19.1035 10.7422L21.1514 12.8753"
      />
      <path
        stroke={strokeColor}
        d="M13.0328 14.5751C14.6323 14.5751 15.9289 11.5362 15.9289 7.78757C15.9289 4.0389 14.6323 1 13.0328 1C11.4333 1 10.1367 4.0389 10.1367 7.78757C10.1367 11.5362 11.4333 14.5751 13.0328 14.5751Z"
      />
      <path
        stroke={strokeColor}
        d="M3.66602 11V15.125C3.66602 15.6313 4.07642 16.0417 4.58269 16.0417C5.08895 16.0417 5.49936 15.6313 5.49936 15.125V11.4583"
      />
    </g>
    <defs>
      <rect width="23" height="17" fill="white" />
      <clipPath id="clip0_9138_113412">
        <rect width="23" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Campaign = svgWrapper(SvgComponent, {
  width: '22px',
  height: '15px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Campaign };
