import { trtClientApi } from 'r/services';

const integrationsApi = {
  fetchAll: async ({ accountId }) => {
    const response = await trtClientApi.get(
      `/account/${accountId}/integrations`
    );
    return response;
  },
  create: async ({ slug, accountId, ...params }) => {
    const response = await trtClientApi.post(
      `/account/${accountId}/integrations`,
      {
        slug,
        auth_properties: {
          ...params,
        },
      }
    );
    return response;
  },

  update: async ({ slug, accountId, ...params }) => {
    const response = await trtClientApi.put(
      `/account/${accountId}/integrations/${slug}`,
      {
        auth_properties: {
          ...params,
        },
      }
    );
    return response;
  },
  delete: async ({ slug, accountId }) => {
    const response = await trtClientApi.delete(
      `/account/${accountId}/integrations/${slug}`
    );
    return response;
  },

  fetchOAuthIntegration: async (slug) => {
    const response = await trtClientApi.get(`/${slug}/auth/integration`);
    return response;
  },
};

export { integrationsApi };
