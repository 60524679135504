import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Drawer, Box, IconButton } from '@mui/material';
import { SettingMenu, Avatar } from 'r/components';
import {
  TreatLogoText,
  NewCustomersIcon,
  ChevronIcon,
} from 'r/components/icons';
import { Link } from 'react-router-dom';
import { useAuth } from 'r/context/auth';
import { WorkspaceDropdown } from './WorkspaceDropdown';
import { NavItems } from './NavItems';
import { primaryNavItems } from './primaryNavItems';
import { sidebarSx } from './sidebarSx';

const Sidebar = ({ navItems }) => {
  const [open, setOpen] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSettingMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleSettingMenuClose = () => {
    setMenuAnchor(null);
  };

  const {
    drawerSx,
    logoContainerSx,
    logoSx,
    toggleButtonSx,
    avatarSx,
    navItemSx,
    settingsMenuContainerSx,
  } = sidebarSx(theme, open);

  return (
    <aside name="page-sidebar" data-testid="page-sidebar">
      <Drawer variant="permanent" sx={drawerSx} open={open}>
        <IconButton
          className="sidebar-toggle"
          aria-label="toggle sidebar"
          size="large"
          onClick={toggleDrawer}
          sx={toggleButtonSx}
        >
          <ChevronIcon />
        </IconButton>
        <Link to="/">
          <Box className="app-logo" sx={logoContainerSx}>
            <TreatLogoText compactDisplay={!open} sx={logoSx} />
          </Box>
        </Link>
        <WorkspaceDropdown open={open} />
        {navItems ? (
          <nav aria-label="Treat Merchant Dashboard">
            <NavItems navItems={navItems} open={open} itemSx={navItemSx} />
          </nav>
        ) : null}
        <Box className="settings-menu-container" sx={settingsMenuContainerSx}>
          <Avatar
            onClick={handleSettingMenuOpen}
            isClicked={Boolean(menuAnchor)}
            compactDisplay={!open}
            sx={avatarSx}
          />
          {menuAnchor && (
            <SettingMenu anchor={menuAnchor} onClose={handleSettingMenuClose} />
          )}
        </Box>
      </Drawer>
    </aside>
  );
};

Sidebar.defaultProps = {
  navItems: primaryNavItems,
};

Sidebar.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.shape({}).isRequired,
      linkTo: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};

const SidebarContainer = () => {
  const { user } = useAuth();
  const navItems = user.current_account.pages
    .map(({ label, link, active }) => {
      return active
        ? {
            label,
            linkTo: link,
            icon: primaryNavItems[link] ?? NewCustomersIcon,
          }
        : null;
    })
    .filter(Boolean);
  return <Sidebar navItems={navItems} />;
};
export { Sidebar, SidebarContainer };
