import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      stroke={strokeColor}
      strokeMiterlimit="10"
      d="M1 1.143L16 17M1 17L16 1.143"
    />
  </svg>
);

const Close = svgWrapper(SvgComponent, {
  width: '17px',
  height: '18px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Close };
