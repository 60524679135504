const NavItemSx = (open) => {
  const linkStyles = {
    display: 'flex',
    alignItems: 'center',
    minHeight: 36,
    height: 36,
    justifyContent: open ? 'initial' : 'center',
    borderRadius: '4px',
    '& .MuiListItemText-root': {
      color: 'text.white',
      fontWeight: (t) => t.typography.fontWeightRegular,
    },
    '&:hover, &:focus': {
      bgcolor: 'interactive.hover',
      textDecoration: 'none',
      '.MuiListItemIcon-root': {
        bgcolor: 'interactive.hover',
      },
    },
    '&.active': {
      bgcolor: 'interactive.focus',
      '.MuiListItemIcon-root': {
        bgcolor: 'interactive.focus',
      },
    },
    '&[aria-disabled="true"]': {
      opacity: 0.38,
      pointerEvents: 'none',
    },
  };

  const iconStyles = {
    minWidth: 0,
    mr: open ? 3 : 0,
    justifyContent: 'center',
  };

  const textStyles = { opacity: open ? 1 : 0 };

  return {
    linkStyles,
    iconStyles,
    textStyles,
  };
};

export default NavItemSx;
