import { useAuth } from 'r/context/auth';
import { ampli } from 'r/ampli';

const Amplitude = () => {
  const { user } = useAuth();
  console.log({ user });
  if (process.env.AMPLITUDE_API_KEY) {
    ampli.load({
      client: {
        apiKey: process.env.AMPLITUDE_API_KEY,
        configuration: {
          defaultTracking: {
            pageViews: { trackHistoryChanges: 'all' },
          },
        },
      },
    });
    if (user) {
      const isAdmin = user.email.match(/@(treat.xyz|trytreat.ai)/);

      ampli.identify(user.user_id, {
        role: isAdmin ? 'internal_user' : 'customer',
      });
      ampli.client.setGroup('accountId', user.current_account.account_id);
    }
  }

  return null;
};

export { Amplitude };
