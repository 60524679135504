import Axios from 'axios';

const axiosConfig = {
  baseURL: '/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
// does not get the global handler
export const trtClientApiAuthCheck = Axios.create(axiosConfig);

// gets a global response handler for 401s
export const trtClientApi = Axios.create(axiosConfig);

// global 401 handler
trtClientApi.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      window.location.href = '/login';
      return null;
    }
    return Promise.reject(error);
  }
);
