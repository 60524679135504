import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';
import { func, node } from 'prop-types';
import { CloseIcon } from 'r/components/icons';

export const DialogTitle = ({ onClose, children }) => {
  return (
    <>
      {Boolean(onClose) && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            p: '10px',
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
        >
          <CloseIcon
            sx={{ width: '15px', height: '15px' }}
            color="interactive.darkblue"
          />
        </IconButton>
      )}
      {Boolean(children) && <MuiDialogTitle>{children}</MuiDialogTitle>}
    </>
  );
};

DialogTitle.defaultProps = {
  children: undefined,
  onClose: undefined,
};

DialogTitle.propTypes = {
  children: node,
  onClose: func,
};
