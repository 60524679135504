import React from 'react';
import { shape, string, bool } from 'prop-types';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

// TODO add image support
const AvatarBase = ({
  firstName,
  companyName,
  isClicked,
  textColor = 'interactive.inverse',
  compactDisplay = false,
  ...props
}) => (
  <List className="avatar-root" {...props}>
    <ListItem
      sx={{
        px: 0,
        justifyContent: 'flex-start',
        py: 0.8,
        cursor: 'pointer',
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: 'unset',
          my: '0',
          mr: compactDisplay ? 0 : 2.5,
          padding: 1,
          transition: 'all .3s',
          '&:hover': {
            backgroundColor: 'background.gray',
            borderRadius: 9999,
          },
          backgroundColor: isClicked && 'background.gray',
          borderRadius: isClicked && 9999,
        }}
      >
        <Avatar
          sx={{
            bgcolor: isClicked ? '#F3B559' : 'treat.brand',
            color: 'treat.text',
            width: 36,
            height: 36,
            borderRadius: 0,
          }}
        >
          <Typography component="span" variant="bodySemibold" sx={{ m: 0 }}>
            {firstName?.slice(0, 1) ?? ''}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        data-testid="profile-avatar-box"
        primary={firstName}
        secondary={companyName}
        primaryTypographyProps={{
          color: textColor,
          variant: 'caption',
          fontWeight: 700,
        }}
        secondaryTypographyProps={{ color: textColor, variant: 'caption' }}
        sx={{ display: compactDisplay ? 'none' : 'block' }}
      />
    </ListItem>
  </List>
);

AvatarBase.defaultProps = {
  firstName: 'Customer',
  companyName: 'Treat',
  textColor: undefined,
  compactDisplay: false,
  props: undefined,
  isClicked: false,
};

AvatarBase.propTypes = {
  firstName: string,
  companyName: string,
  isClicked: bool,
  textColor: string,
  compactDisplay: bool,
  props: shape({}),
};

export { AvatarBase };
