import { instanceOf, shape, string } from 'prop-types';
import { svgWrapper } from './svgWrapper';

const SvgComponent = ({ strokeColor, forwardedRef, ...props }) => (
  <svg
    ref={forwardedRef}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5385 12.4609V15.2302C18.5385 16.7532 17.1538 17.9994 15.4923 17.9994H4.04615C2.38462 17.9994 1 16.7532 1 15.2302V12.4609"
      stroke={strokeColor}
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.76855 0V12.4615"
      stroke={strokeColor}
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.15332 7.84375L9.7687 12.4591L14.3841 7.84375"
      stroke={strokeColor}
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const Download = svgWrapper(SvgComponent, {
  width: '24px',
  height: '24px',
});

SvgComponent.defaultProps = {
  strokeColor: '',
  forwardedRef: null,
};

SvgComponent.propTypes = {
  strokeColor: string,
  forwardedRef: shape({ current: instanceOf(Element) }),
};

export { Download };
