/**
 * Return Sidebar component styles and props
 *
 * @param   {obj}  theme  Theme object, imported to component and used for mixins.
 * @param   {bool}  open  Is the sidebar drawer open or closed?
 *
 * @return  {obj}         Object of style/props objects.
 */
const sidebarSx = (theme, open) => {
  const logoContainerSx = {
    pt: '20px',
    pl: open ? '12px' : '20px',
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };
  const drawerSx = {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    color: theme.palette.text.white,
    overflowX: 'hidden',
    ...(open && {
      '&, & .MuiDrawer-paper': {
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: '200px',
      },
    }),
    ...(!open && {
      '&, & .MuiDrawer-paper': {
        backgroundColor: theme.palette.background.blue,
        visibility: 'visible',
        width: '68px',
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }),
    '.MuiIconButton-root.sidebar-toggle': {
      '&:hover, &:focus': {
        backgroundColor: 'white',
      },
    },
    '.MuiDrawer-paper, .MuiListItemIcon-root': {
      backgroundColor: theme.palette.background.blue,
      color: theme.palette.text.white,
    },
    '.MuiDrawer-paper': {
      boxShadow: theme.dropShadows.leftNav,
      overflow: 'visible',
      p: 1,
      zIndex: 1302, // Higher than overlay or popovers (dropdown menus)
    },
    '& a': {
      textDecoration: 'none',
    },
    '& a[aria-disabled="true"]': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    '& .MuiListItemIcon-root > svg': {
      width: '20px',
      height: 'auto',
      opacity: 1,
    },
    '& a[href="/saved-views"] .MuiListItemIcon-root, & [data-href="/saved-views"] .MuiListItemIcon-root':
      {
        mr: '17px',
        '& > svg': {
          width: '17px',
        },
      },
    '& a[href="/enrichment"] .MuiListItemIcon-root, & [data-href="/enrichment"] .MuiListItemIcon-root':
      {
        mr: '12px',
        '& > svg': {
          width: '22px',
        },
      },
    '& a[href="/"] .MuiListItemIcon-root': {
      mr: '11px',
      '& > svg': {
        width: '24px',
      },
    },
    '& a[href="/campaigns"] .MuiListItemIcon-root': {
      mr: '10px',
      '& > svg': {
        width: '24px',
      },
    },
  };

  const selectSx = {
    color: theme.palette.treat.text,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,.25)',
    height: 36,
    py: 2.8,

    '& .MuiSelect-select': {
      py: 1.6,
      pl: 2.4,
    },

    '& svg.MuiSelect-icon': {
      right: 14,
      top: 'calc(50% - 0.2em)',
    },

    '&.Mui-focused:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
    },
    '&:hover:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '&.Mui-focused': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& svg': {
      color: theme.palette.treat.text,
    },
  };

  const selectMenuProps = {
    PaperProps: {
      sx: {
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.treat.text,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(255,255,255,.25)',
        width: '100%',
        maxWidth: 189,
        left: '5px !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: -0.2,

        '& li.MuiMenuItem-root': {
          height: 36,
          py: 2,
          pl: 4,
          fontSize: 14,
          lineHeight: '22px',
          mb: 0.2,

          '&:hover': {
            backgroundColor: theme.palette.interactive.hover,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  };

  const toggleButtonSx = {
    height: '22px',
    width: '22px',
    position: 'absolute',
    top: '23px',
    right: '-13px',
    background: theme.palette.background.gray,
    padding: 0,
    boxShadow: theme.dropShadows.callout,
    '& > svg': {
      height: '5px',
      position: 'relative',
      ...(open && {
        rotate: '-90deg',
        left: '-1px',
      }),
      ...(!open && {
        rotate: '90deg',
        left: '1px',
      }),
    },
  };

  const logoSx = {
    height: '20px',
    width: 'auto',
    flexShrink: 0,
  };

  const avatarSx = {
    '.MuiAvatar-root': {
      width: open ? '36px' : '26px',
      height: open ? '36px' : '26px',
      transition: theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  };

  const navItemSx = { pl: '17px', pr: '15px' };

  const settingsMenuContainerSx = { px: open ? 3 : 2, mt: 'auto' };

  return {
    navItemSx,
    logoSx,
    toggleButtonSx,
    logoContainerSx,
    drawerSx,
    selectSx,
    selectMenuProps,
    avatarSx,
    settingsMenuContainerSx,
  };
};

export { sidebarSx };
