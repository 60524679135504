import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { color as muiColor } from '@mui/system';
import { shape, string } from 'prop-types';

const svgWrapper = (
  svgComponent,
  defaultSize = {},
  { defaultColor = 'text.primary' } = {}
) => {
  const ForwardRefComponent = React.forwardRef(
    ({ color = 'currentColor', sx, ...props } = {}, ref) => {
      const theme = useTheme();
      let strokeColor = color;
      if (color === 'themeDefault') {
        strokeColor = defaultColor;
      }

      strokeColor = muiColor({ color: strokeColor, theme }).color;

      const StyledLogo = styled(svgComponent)({
        ...defaultSize,
        ...sx,
      });

      return <StyledLogo {...{ strokeColor, forwardedRef: ref, ...props }} />;
    }
  );

  ForwardRefComponent.defaultProps = {
    color: 'currentColor',
    sx: {},
  };

  ForwardRefComponent.propTypes = {
    color: string,
    sx: shape({}),
  };

  return ForwardRefComponent;
};

export { svgWrapper };
