import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { Button } from 'r/components';
import { DialogTitle } from './DialogTitle';

const InfoDialog = ({
  open,
  onClose,
  sx = {},
  headerText,
  bodyText,
  buttonText,
  dataTestid = '',
} = {}) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      sx={sx}
      PaperProps={{ 'data-testid': dataTestid }}
    >
      <DialogTitle onClose={onClose}>{headerText}</DialogTitle>
      <DialogContent>
        <Typography variant="body" align="center">
          {bodyText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="large" variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InfoDialog.defaultProps = {
  sx: undefined,
  dataTestid: '',
};

InfoDialog.propTypes = {
  /**
   * Header text
   */
  headerText: PropTypes.node.isRequired,
  /**
   * Header text
   */
  bodyText: PropTypes.node.isRequired,
  /**
   * Header text
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * onClick handler for close button.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Open state for dialog.
   */
  open: PropTypes.bool.isRequired,
  /**
   * (Optional) Additional style props.
   */
  sx: PropTypes.shape({}),
  dataTestid: PropTypes.string,
};

export { InfoDialog };
