import { node } from 'prop-types';
import { uuidv4 } from 'r/theme/utils';
import {
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { useIsMounted } from 'r/hooks/useIsMounted';
import { useGlobalError } from 'r/context/globalError';
import { useSearchParams } from 'react-router-dom';
import { conceptsApi } from './conceptsApi';
import { useAuth } from '../auth';
import {
  transformStateForApiRequest,
  transformFilterStateToApiFormat,
} from './transformStateForApiRequest';

const ConceptsContext = createContext();

const computeParams = (metaData) => {
  const paramUpdates = {};
  const {
    sid: nextSid,
    sort_direction: sd,
    sort_column: sc,
    current_page: metaPage,
  } = metaData;

  // TODO
  // don't update if it's already in the URL (it will be, for back/forward nav)
  // as that will cause a double entry in browser history
  if (nextSid) {
    paramUpdates.sid = nextSid;
  }
  if (sd) {
    paramUpdates.sortDirection = sd;
  }
  if (sd) {
    paramUpdates.sortColumn = sc;
  }
  if (metaPage) {
    paramUpdates.page = metaPage;
  }
  return paramUpdates;
};

const initialState = {
  concepts_count: 0,
  data: [],
  unexplored_topics: [],
  meta: {
    current_page: 1,
    next_page: 2,
    prev_page: 0,
    per_page: 10,
    total_pages: 0,
    sort_column: 'ctr',
    sort_direction: 'desc',
    available_sort_columns: [],
    filters: [],
    applied_filters: [],
  },
};
function ConceptsProvider({ children }) {
  const { setErrorToShow } = useGlobalError();
  const [, setSearchParams] = useSearchParams();

  const [concepts, setConcepts] = useState(initialState);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const accountId = user?.current_account_id;

  const isMounted = useIsMounted();
  // called when API responds, recalc data for UI
  const updateStateFromApiResponse = useCallback((data) => {
    const nextState = {
      ...data,
    };

    const appliedFilters = data.meta.filters
      .filter((f) => f.applied)
      .map((af) => {
        return {
          ...af,
          filter_values: af.filter_values.filter((afv) => afv.selected),
        };
      })
      .filter(Boolean);

    setConcepts((prevState) => {
      return {
        ...prevState,
        ...nextState,
        meta: {
          ...nextState.meta,
          applied_filters: appliedFilters,
        },
      };
    });
  }, []);

  const initializePage = useCallback(
    async ({
      page,
      sid,
      sortDirection: sortDirectionQueryParam,
      sortColumn: sortColumnQueryParam,
    }) => {
      // don't fetch if we already have it
      if (sid === concepts.meta.sid) {
        return;
      }
      setLoading(true);
      const dateFilterKey = 'date_bucket';
      const { sortDirection, sortColumn } =
        transformStateForApiRequest(concepts);
      const response = await conceptsApi(accountId, {
        page,
        sid,
        sort_direction: sortDirectionQueryParam ?? sortDirection,
        sort_column: sortColumnQueryParam ?? sortColumn,
        filters: [
          {
            filter_key: dateFilterKey,
            filter_values: ['all_time'],
            filter_types: ['any'],
          },
        ].filter(Boolean),
      }).catch((error) => setErrorToShow(error));

      if (response?.data && isMounted.current) {
        updateStateFromApiResponse(response.data);
        const paramUpdates = computeParams(response.data.meta);

        // if we update, always include SID
        if (Object.keys(paramUpdates).length > 0) {
          setSearchParams({ ...paramUpdates, sid: paramUpdates.sid });
        }

        setLoading(false);
      }
    },

    [
      accountId,
      isMounted,
      setErrorToShow,
      updateStateFromApiResponse,
      concepts,
      setSearchParams,
    ]
  );

  const sendUpdateToApi = useCallback(
    async (update) => {
      // merge old state w/ updated piece of state
      const { page, filters, sortDirection, sortColumn } =
        transformStateForApiRequest(concepts);

      setLoading(true);
      const response = await conceptsApi(accountId, {
        sort_direction: sortDirection,
        sort_column: sortColumn,
        page,
        filters,
        ...update,
      }).catch((error) => setErrorToShow(error));

      if (response?.data && isMounted.current) {
        updateStateFromApiResponse(response.data);
        const paramUpdates = computeParams(response.data.meta);

        // if we update, always include SID
        if (Object.keys(paramUpdates).length > 0) {
          setSearchParams({ ...paramUpdates, sid: paramUpdates.sid });
        }

        setLoading(false);
      }
    },
    [
      concepts,
      accountId,
      isMounted,
      setErrorToShow,
      updateStateFromApiResponse,
      setSearchParams,
    ]
  );

  const onPageChange = useCallback(
    async (page) => {
      const update = {
        page,
      };
      sendUpdateToApi(update);
    },
    [sendUpdateToApi]
  );

  const onSortColumnChange = useCallback(
    async (value) => {
      const update = {
        sort_column: value,
        sort_direction: 'desc',
        page: 1,
      };
      sendUpdateToApi(update);
    },
    [sendUpdateToApi]
  );

  const onSortDirectionChange = useCallback(
    async (value) => {
      const update = {
        sort_direction: value,
        page: 1,
      };
      sendUpdateToApi(update);
    },
    [sendUpdateToApi]
  );

  // Note, we have 2 filter areas
  // 1, in the header
  // 2, in drawer
  const onFilterChange = useCallback(
    async (filterData) => {
      const dateBucket = concepts.meta.applied_filters.filter(
        (f) => f.filter_key === 'date_bucket'
      );
      const update = {
        filters: [
          ...transformFilterStateToApiFormat([...filterData, ...dateBucket]),
        ],
        page: 1,
        sort_direction: 'desc',
      };
      sendUpdateToApi(update);
    },
    [sendUpdateToApi, concepts.meta.applied_filters]
  );

  const onDateFilterChange = useCallback(
    (value) => {
      const filterKey = 'date_bucket';
      const orderDate = concepts.meta.applied_filters.find(
        (f) => f.filter_key === filterKey
      );

      let filter;
      if (orderDate) {
        orderDate.filter_values = value;
      } else {
        filter = {
          filter_key: filterKey,
          index: uuidv4(),
          filter_types: ['any'],
          filter_values: value,
        };
      }

      const update = {
        filters: transformFilterStateToApiFormat(
          [...concepts.meta.applied_filters, filter].filter(Boolean)
        ),
        page: 1,
        sort_direction: 'desc',
      };
      sendUpdateToApi(update);
    },
    [sendUpdateToApi, concepts.meta.applied_filters]
  );

  const value = useMemo(
    () => ({
      concepts,
      initializePage,
      loading,
      onPageChange,
      onFilterChange,
      onSortColumnChange,
      onSortDirectionChange,
      onDateFilterChange,
    }),
    [
      concepts,
      initializePage,
      loading,
      onPageChange,
      onFilterChange,
      onSortColumnChange,
      onSortDirectionChange,
      onDateFilterChange,
    ]
  );
  return (
    <ConceptsContext.Provider value={value}>
      {children}
    </ConceptsContext.Provider>
  );
}

function useConcepts() {
  const context = useContext(ConceptsContext);

  if (context === undefined) {
    throw new Error('useConcepts must be used within a ConceptsProvider');
  }
  return context;
}

ConceptsProvider.propTypes = {
  children: node.isRequired,
};
export { ConceptsProvider, useConcepts, ConceptsContext };
