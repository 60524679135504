import { useEffect } from 'react';
import propTypes from 'prop-types';
import { useDashboard } from 'r/context/dashboard';
import { useSearchParams } from 'react-router-dom';

// This component handles initialization from URL params (SID/channel/campaign).
// It DOES NOT handle changes to dashboard filters
function DashboardContainer({ page, children }) {
  const { initializeDashboard, dashboard, loading } = useDashboard();
  const [searchParams] = useSearchParams();

  const sid = searchParams.get('sid');
  const channel = searchParams.get('channel');
  const campaign = searchParams.get('campaign');
  const dateFilter = searchParams.get('dateFilter');
  const source = searchParams.get('source');

  // this block handles initial page load scenarios,
  // so loadin var is always === true
  useEffect(() => {
    // skip if this is not the initial page load, or if channel/campaign param is set
    if (dashboard.sid || channel || campaign) {
      return;
    }

    initializeDashboard({ page, sid });
  }, [campaign, channel, dashboard.sid, initializeDashboard, page, sid]);

  // back/fwd, undo/redo filters, compare URL params to state
  useEffect(() => {
    // skip if this is the initial page load, or if channel/campaign param is set
    if (loading || channel || campaign) {
      return;
    }

    if (sid !== dashboard.sid || dashboard.page !== page) {
      // Reset selected filters state if we are navigaging to a different page
      initializeDashboard({ page, sid }, dashboard.page !== page);
    }
  }, [
    campaign,
    channel,
    dashboard.page,
    dashboard.sid,
    initializeDashboard,
    loading,
    page,
    sid,
  ]);

  // Apply campaign or channel filter if campaign or channel URL param is set
  useEffect(() => {
    // skip if the campaign/channel param is not set
    if (!(channel || campaign)) {
      return;
    }

    // Initialize if:
    // 1. the param was added in an existing Treat window (`dashboard.sid` already exists)
    //   - in this case we need to check for `!loading` to prevent multiple initializations because
    //     the param isn't immediately removed
    // 2. we are initializing a new page with the channel/campaign param (`!dashboard.sid` is true)
    //   - in this case `loading` is true from the very beginning, and `dashboard.sid` will be
    //     set in the next render, so we don't check for `loading` to prevent multiple inits
    const shouldInitialize = (dashboard.sid && !loading) || !dashboard.sid;
    if (!shouldInitialize) {
      return;
    }

    initializeDashboard(
      {
        page,
        channel,
        campaign,
        source,
        dateFilter: dateFilter && JSON.parse(dateFilter),
      },
      true
    );
  }, [
    campaign,
    channel,
    dashboard.sid,
    dateFilter,
    initializeDashboard,
    loading,
    page,
    source,
  ]);

  return children;
}

DashboardContainer.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
    propTypes.func,
  ]).isRequired,
};

export { DashboardContainer };
