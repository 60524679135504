import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { Typography } from '@mui/material';

const PageTitle = ({ children, sx, ...props } = {}) => (
  <Typography
    className="page-title"
    variant="displayLarge"
    component="h2"
    sx={{ lineHeight: 1.2, mb: 0, mr: 2, ...sx }}
    {...props}
  >
    {children}
  </Typography>
);

PageTitle.defaultProps = {
  sx: {},
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sx: shape({}),
};

export { PageTitle };
