import { Box, Typography } from '@mui/material';
import { DisplayXLarge, DisplayBody } from 'r/components';
import { TreatLogoText } from 'r/components/icons';
import merge from 'lodash/merge';
import { node, shape, string } from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';

const DefaultFooter = ({ children }) => (
  <Box
    component="footer"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      p: 10,
      textAlign: 'center',
    }}
  >
    {children}
  </Box>
);

const DefaultHeader = () => (
  <Box
    component="header"
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      p: '32px',
    }}
  >
    <TreatLogoText
      colorScheme="publicPage"
      sx={{ height: '27px', width: 'auto' }}
    />
  </Box>
);

const HeadingCenteredPublic = ({ heading, children, sx = {}, ...props }) => (
  <Box
    className="heading-centered-public"
    {...props}
    sx={merge(
      {
        mb: 6,
        textAlign: 'center',
        '.display-xl': {
          mb: '5px',
        },
      },
      sx
    )}
  >
    <DisplayXLarge>{heading}</DisplayXLarge>
    {children ? <DisplayBody>{children}</DisplayBody> : null}
  </Box>
);

const ContentCenteredPublic = ({ children, sx = {}, ...props } = {}) => (
  <Box
    className="content-centered-public"
    {...props}
    sx={merge(
      {
        margin: '0 auto',
        maxWidth: 'max-content',
        textAlign: 'center',
        '.form-fields-stack': {
          maxWidth: '394px',
          margin: '0 auto 26px',
          '> .component-input': {
            mb: 2,
          },
          '.name-fields-stack .component-input': {
            mb: 2,
          },
        },
        '.checkbox-group': {
          mb: '22px',
          mt: '16px',
        },
        button: {
          '&:not(.MuiIconButton-root)': {
            width: '100%',
          },
        },
      },
      sx
    )}
  >
    {children}
  </Box>
);

const PageLayoutPublic = () => {
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.white',
        borderTop: '12px solid',
        borderTopColor: 'background.blue',
      }}
    >
      <DefaultHeader />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Box>
      <DefaultFooter>
        <Typography variant="captionPublicSubdued" sx={{ maxWidth: '700px' }}>
          {pathname.includes('signup') ? (
            <>
              By creating an account, you agree to our{' '}
              <a href="https://www.treat.xyz/terms">Terms of Use</a> and have
              read and acknowledged the
              <a href="https://www.treat.xyz/privacy">Privacy Policy</a>
              .
              <br />© 2023 Treat Technologies, Inc.
            </>
          ) : (
            '© 2023 Treat Technologies, Inc.'
          )}
        </Typography>
      </DefaultFooter>
    </Box>
  );
};

DefaultFooter.defaultProps = {
  children: null,
};

DefaultFooter.propTypes = {
  children: node,
};

ContentCenteredPublic.defaultProps = {
  sx: {},
  children: null,
};

ContentCenteredPublic.propTypes = {
  children: node,
  sx: shape({}),
};

HeadingCenteredPublic.defaultProps = {
  sx: {},
  children: null,
};

HeadingCenteredPublic.propTypes = {
  heading: string.isRequired,
  children: node,
  sx: shape({}),
};

export { PageLayoutPublic, ContentCenteredPublic, HeadingCenteredPublic };
