import { node } from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'r/context/auth';
import * as FullStory from '@fullstory/browser';

function ProtectedRoute({ children }) {
  const { user } = useAuth();

  if (user && FullStory.isInitialized()) {
    FullStory.setUserVars({
      displayName: `${user.first_name} ${user?.last_name}`,
      email: user?.email,
    });
  }

  return user ? (
    children || <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} /> // eslint-disable-line no-restricted-globals
  );
}

ProtectedRoute.defaultProps = {
  children: undefined,
};

ProtectedRoute.propTypes = {
  children: node,
};

export default ProtectedRoute;
