import { node } from 'prop-types';
import {
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
  useRef,
} from 'react';
import { useIsMounted } from 'r/hooks/useIsMounted';
import consumer from 'r/services/cable';

// import { mkadsApi } from './mkadsApi';
import { trtClientApi } from 'r/services';
import { useAuth } from '../auth';

const MkadsContext = createContext();

const initialState = {
  generations_count: 0,
  session: {},
  generations: [],
  available_product_images: [],
  meta: {
    current_page: 1,
    next_page: 2,
    prev_page: 0,
    per_page: 10,
    total_pages: 0,
  },
};

const MkadsProvider = ({ children }) => {
  const [mkads, setMkads] = useState(initialState);
  const [activeGenerationId, setActiveGenerationId] = useState();
  const [selectedImageId, setSelectedImageId] = useState();
  const [overrides, setOverrides] = useState({});
  const [dimensions, setDimensions] = useState({});
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const accountId = user?.current_account_id;
  const isMounted = useIsMounted();

  const generationsRef = useRef();
  generationsRef.current = mkads.generations;

  const activeGenerationIdRef = useRef();
  activeGenerationIdRef.current = activeGenerationId;

  // initialize websocket
  const mkadsChannel = consumer.subscriptions.create(
    {
      channel: 'MkadsChannel',
      id: mkads.session.sid,
    },
    {
      connected: () => {
        // do something
        console.log('>>> connected'); // eslint-disable-line no-console
      },
      received: (payload) => {
        if (payload.action === 'generation_created') {
          const {
            generation_id: generationId,
            created_at: createdAt,
            created_at_display: createdAtDisplay,
            num_images_expected: numImagesExpected,
          } = payload.data;
          setActiveGenerationId(generationId);
          const updatedGenerations = [
            {
              generation_id: generationId,
              images: Array(numImagesExpected).fill({}),
              created_at: createdAt,
              created_at_display: createdAtDisplay,
              num_images_expected: numImagesExpected,
            },
          ].concat(generationsRef.current);
          setMkads((prevState) => {
            return {
              ...prevState,
              generations: updatedGenerations,
            };
          });
        }
        if (payload.action === 'generation_complete') {
          const { generation_id: generationId } = payload.data;
          if (activeGenerationIdRef.current === generationId) {
            setActiveGenerationId(undefined);
          }
        }
        if (payload.action === 'image_generated') {
          const {
            generation_id: generationId,
            generation_seqnum: generationSeqnum,
            image_uri: imageUri,
            image_id: imageId,
            request,
          } = payload.data;
          const updatedGenerations = generationsRef.current.map(
            (generation) => {
              const g = generation;
              if (g.generation_id === generationId) {
                g.images[generationSeqnum] = {
                  image_id: imageId,
                  image_uri: imageUri,
                  request,
                };
              }
              return g;
            }
          );
          setMkads((prevState) => {
            return {
              ...prevState,
              generations: updatedGenerations,
            };
          });
        }
      },
      disconnected: () => {
        // do something
        console.log('>>> disconnected'); // eslint-disable-line no-console
      },
    }
  );

  // called when API responds, recalc data for UI
  const updateStateFromApiResponse = useCallback((data) => {
    const nextState = {
      ...data,
    };

    setMkads((prevState) => {
      return {
        ...prevState,
        ...nextState,
        meta: {
          ...nextState.meta,
        },
      };
    });
  }, []);

  const currentStep = useCallback(() => {
    return mkads.session.current_step;
  }, [mkads.session]);

  const selectedProductImageId = useCallback(() => {
    return mkads.session.product_image
      ? mkads.session.product_image.product_image_id
      : null;
  }, [mkads.session.product_image]);

  const canGenerate = useCallback(() => {
    if (currentStep() === 1)
      return !activeGenerationId && !!selectedProductImageId();
    if (currentStep() === 2) {
      return (
        !activeGenerationId && !!selectedProductImageId() && !!selectedImageId
      );
    }
    return false;
  }, [
    activeGenerationId,
    selectedProductImageId,
    selectedImageId,
    currentStep,
  ]);

  const canAdvance = useCallback(() => {
    return !!selectedImageId;
  }, [selectedImageId]);

  const canGoBack = useCallback(() => {
    return !activeGenerationId;
  }, [activeGenerationId]);

  const canSelectImage = useCallback(() => {
    return !activeGenerationId && !!selectedProductImageId();
  }, [activeGenerationId, selectedProductImageId]);

  /*
    Initialize if there's no existing sid in the URI
  */
  const initSession = useCallback(async () => {
    setLoading(true);
    const response = await trtClientApi.post(`/mkads/${accountId}/init`);

    if (response?.data && isMounted.current) {
      updateStateFromApiResponse(response.data);
    }
  }, [updateStateFromApiResponse, accountId, isMounted]);

  /*
    Fetch an existing sid from the URI
  */
  const fetchGenerations = useCallback(
    async ({ sid, step }) => {
      const response = await trtClientApi.get(
        `/mkads/${accountId}/${sid}/generations`,
        {
          params: { step },
        }
      );
      if (response?.data && isMounted.current) {
        setMkads((prevState) => {
          return {
            ...prevState,
            generations: response.data.generations,
          };
        });
        setOverrides(response.data.generations[0]?.overrides ?? {});
        setDimensions(response.data.generations[0]?.dimensions ?? {});
      }
    },
    [accountId, isMounted]
  );

  const fetchSession = useCallback(
    async ({ sid }) => {
      setLoading(true);
      const response = await trtClientApi.get(`/mkads/${accountId}/${sid}`);

      if (response?.data && isMounted.current) {
        updateStateFromApiResponse(response.data);
      }
      await fetchGenerations({
        sid,
        step: response.data.session.current_step ?? 1,
      });
    },
    [updateStateFromApiResponse, accountId, isMounted, fetchGenerations]
  );

  /*
    Select product image
  */
  const setProductImageId = useCallback(
    async (productImageId) => {
      const response = await trtClientApi.put(
        `/mkads/${accountId}/${mkads.session.sid}`,
        {
          product_image_id: productImageId,
        }
      );
      if (response?.data && isMounted.current) {
        updateStateFromApiResponse(response.data);
      }
    },
    [updateStateFromApiResponse, accountId, isMounted, mkads.session.sid]
  );

  const findImage = useCallback(
    (imageId) => {
      let si;
      mkads.generations.forEach((generation) => {
        generation.images.forEach((image) => {
          if (image.image_id === imageId) {
            si = image;
          }
        });
      });
      return si;
    },
    [mkads.generations]
  );
  const selectedImage = useCallback(() => {
    findImage(selectedImageId);
  }, [findImage, selectedImageId]);

  /*
    Generate a new batch of images
  */
  const generate = useCallback(async () => {
    mkadsChannel.send({
      action: 'generate',
      sid: mkads.session.sid,
      overrides,
      dimensions,
      image_id: selectedImageId,
    });
    setSelectedImageId(undefined);
  }, [mkads, mkadsChannel, overrides, dimensions, selectedImageId]);

  const advance = useCallback(async () => {
    if (currentStep() === 1) {
      const response = await trtClientApi.post(
        `/mkads/${accountId}/${mkads.session.sid}/advance`,
        { image_id: selectedImageId }
      );

      if (response?.data && isMounted.current) {
        await fetchSession({ sid: mkads.session.sid });
        setSelectedImageId(undefined);
        generate();
      }
    }
  }, [
    currentStep,
    isMounted,
    accountId,
    fetchSession,
    mkads.session.sid,
    selectedImageId,
    generate,
  ]);

  const goBack = useCallback(async () => {
    if (currentStep() === 2) {
      const response = await trtClientApi.post(
        `/mkads/${accountId}/${mkads.session.sid}/go_back`,
        { image_id: selectedImageId }
      );

      if (response?.data && isMounted.current) {
        await fetchSession({ sid: mkads.session.sid });
      }
    }
  }, [
    currentStep,
    accountId,
    fetchSession,
    isMounted,
    mkads.session.sid,
    selectedImageId,
  ]);

  const toggleSelectImage = useCallback(
    async (imageId) => {
      if (!canSelectImage()) return;
      if (imageId === selectedImageId) {
        setSelectedImageId(undefined);
      } else {
        setSelectedImageId(imageId);
        const image = findImage(imageId);
        const {
          dim1,
          dim2,
          dim3,
          dim1_color: dim1Color,
          dim2_color: dim2Color,
          dim3_color: dim3Color,
        } = image?.request ?? {};
        setDimensions({
          ...dimensions,
          dim1,
          dim2,
          dim3,
          dim1_color: dim1Color,
          dim2_color: dim2Color,
          dim3_color: dim3Color,
        });
      }
    },
    [
      selectedImageId,
      setSelectedImageId,
      canSelectImage,
      dimensions,
      setDimensions,
      findImage,
    ]
  );

  const value = useMemo(
    () => ({
      mkads,
      initSession,
      fetchSession,
      setProductImageId,
      generate,
      loading,
      activeGenerationId,
      setActiveGenerationId,
      selectedImageId,
      setSelectedImageId,
      canGenerate,
      canAdvance,
      canGoBack,
      selectedProductImageId,
      toggleSelectImage,
      advance,
      goBack,
      currentStep,
      overrides,
      setOverrides,
      selectedImage,
      dimensions,
      setDimensions,
    }),
    [
      mkads,
      initSession,
      fetchSession,
      setProductImageId,
      generate,
      loading,
      activeGenerationId,
      setActiveGenerationId,
      selectedImageId,
      setSelectedImageId,
      canGenerate,
      canAdvance,
      canGoBack,
      selectedProductImageId,
      toggleSelectImage,
      advance,
      goBack,
      currentStep,
      overrides,
      setOverrides,
      selectedImage,
      dimensions,
      setDimensions,
    ]
  );
  return (
    <MkadsContext.Provider value={value}>{children}</MkadsContext.Provider>
  );
};

const useMkads = () => {
  const context = useContext(MkadsContext);

  if (context === undefined) {
    throw new Error('useMkads must be used within a MkadsProvider');
  }
  return context;
};

MkadsProvider.propTypes = {
  children: node.isRequired,
};
export { MkadsProvider, useMkads, MkadsContext };
