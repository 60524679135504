import { Typography } from '@mui/material';
import { shape, node } from 'prop-types';

const DisplayBody = ({ children, ...props }) => (
  <Typography variant="body" component="p" {...props}>
    {children}
  </Typography>
);

DisplayBody.defaultProps = {
  props: {},
};

DisplayBody.propTypes = {
  children: node.isRequired,
  props: shape({}),
};

export { DisplayBody };
