import isUndefined from 'lodash/isUndefined';

const transformOperatorToFilterType = (operator = 'is') => {
  return operator === 'is not' ? ['none'] : ['any'];
};

const transformFilterStateToApiFormat = (filters = []) => {
  return filters.map((sf) => {
    return {
      filter_types: transformOperatorToFilterType(sf.operator),
      filter_key: sf.category,
      filter_values: sf.value.filter((v) => {
        return v !== '' && !isUndefined(v);
      }),
    };
  });
};

const selectedFilters = (state) => {
  return transformFilterStateToApiFormat(state.selectedFilters);
};

const selectedPage = (state) => {
  return state.page;
};

// we are always merging this with an update, so we don't need to calculate here
const transformStateForApiRequest = (state) => {
  return {
    filters: selectedFilters(state),
    page: selectedPage(state),
  };
};

export { transformStateForApiRequest, transformFilterStateToApiFormat };
