import theme from 'r/theme';

export const globalCss = {
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.body.lineHeight,
  },
  h1: {
    fontFamily: theme.typography.displayXLarge.fontFamily,
    fontSize: theme.typography.displayXLarge.fontSize,
    fontWeight: theme.typography.displayXLarge.fontWeight,
    lineHeight: theme.typography.displayXLarge.lineHeight,
    margin: theme.typography.displayXLarge.margin,
  },
  h2: {
    fontFamily: theme.typography.displayLarge.fontFamily,
    fontSize: theme.typography.displayLarge.fontSize,
    fontWeight: theme.typography.displayLarge.fontWeight,
    lineHeight: theme.typography.displayLarge.lineHeight,
    margin: theme.typography.displayLarge.margin,
  },
  h3: {
    fontFamily: theme.typography.displayMedium.fontFamily,
    fontSize: theme.typography.displayMedium.fontSize,
    fontWeight: theme.typography.displayMedium.fontWeight,
    lineHeight: theme.typography.displayMedium.lineHeight,
    margin: theme.typography.displayMedium.margin,
  },
  h4: {
    fontFamily: theme.typography.displaySmall.fontFamily,
    fontSize: theme.typography.displaySmall.fontSize,
    fontWeight: theme.typography.displaySmall.fontWeight,
    lineHeight: theme.typography.displaySmall.lineHeight,
    margin: theme.typography.displaySmall.margin,
  },
  h5: {
    fontFamily: theme.typography.displaySmall.fontFamily,
    fontSize: theme.typography.displaySmall.fontSize,
    fontWeight: theme.typography.displaySmall.fontWeight,
    lineHeight: theme.typography.displaySmall.lineHeight,
    margin: theme.typography.displaySmall.margin,
  },
  h6: {
    fontFamily: theme.typography.displaySmall.fontFamily,
    fontSize: theme.typography.displaySmall.fontSize,
    fontWeight: theme.typography.displaySmall.fontWeight,
    lineHeight: theme.typography.displaySmall.lineHeight,
    margin: theme.typography.displaySmall.margin,
  },
  figcaption: {
    display: 'inline-block',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
  },
  strong: {
    fontWeight: theme.typography.fontWeight,
  },
  a: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    '&:focus, &:hover': {
      textDecoration: 'underline',
    },
    '&.link-button:focus, &.link-button:hover': {
      textDecoration: 'none',
    },
  },
  hr: {
    border: 0,
    borderTop: `1px solid ${theme.palette.common.border}`,
    margin: '8px 0 14px',
  },
  '.no-widow': {
    whiteSpace: 'nowrap',
  },
  '.recharts-tooltip-wrapper': {
    zIndex: '1303',
  },
};
