import { bool, string } from 'prop-types';
import { memo } from 'react';
import { NavItem } from './NavItem';

const SidebarNavItem = memo(({ itemLink, disabled, ...props }) => {
  return (
    <li className="nav-item sidebar">
      <NavItem itemLink={itemLink} disabled={disabled} {...props} />
    </li>
  );
});

SidebarNavItem.defaultProps = {
  disabled: false,
};

SidebarNavItem.propTypes = {
  disabled: bool,
  itemLink: string.isRequired,
};

export { SidebarNavItem };
