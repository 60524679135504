import {
  createContext,
  useMemo,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { useDashboard } from 'r/context/dashboard';

export const ComparandsContext = createContext({
  availableComparands: [],
  selectedComparands: [],
  updateComparands: noop,
  areComparandsSelected: true,
});

export function ComparandsProvider({ children }) {
  const { dashboard, loading: isDashboardLoading } = useDashboard();
  const [selectedComparands, setSelectedComparands] = useState([]);

  useEffect(() => {
    if (dashboard.comparands?.length && !isDashboardLoading) {
      setSelectedComparands(dashboard.comparands);
    }
  }, [dashboard.comparands, isDashboardLoading]);

  const availableComparands = useMemo(
    () =>
      dashboard.available_comparands?.map((comparand) => ({
        label: comparand.display_key,
        value: comparand.comparand_key,
        image:
          typeof comparand.image === 'string' ? comparand.image : undefined,
      })) || [],
    [dashboard.available_comparands]
  );

  const updateComparands = useCallback((dropdownAValue, dropdownBValue) => {
    setSelectedComparands([
      { comparand_keys: dropdownAValue },
      { comparand_keys: dropdownBValue },
    ]);
  }, []);

  const contextValue = useMemo(
    () => ({
      availableComparands,
      selectedComparands,
      updateComparands,
      areComparandsSelected:
        selectedComparands.filter((c) => c.comparand_keys?.length).length === 2,
    }),
    [availableComparands, selectedComparands, updateComparands]
  );

  return (
    <ComparandsContext.Provider value={contextValue}>
      {children}
    </ComparandsContext.Provider>
  );
}

ComparandsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useComparands() {
  return useContext(ComparandsContext);
}
