import { node } from 'prop-types';
import React, { useContext, createContext } from 'react';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const value = React.useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

AuthProvider.propTypes = {
  children: node.isRequired,
};

export { AuthProvider, useAuth, AuthContext };
