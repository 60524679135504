import { Suspense, lazy } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

// Theme.
import theme from 'r/theme';

// Components.
import AppInitialize from 'r/components/AppInitialize';
import {
  TreatGlobalStyles,
  Amplitude,
  Logout,
  DashboardContainer,
  PageLayoutPublic,
  PageLayoutSidebar,
  PageLazyLoadSkeleton,
  ConceptsContainer,
} from 'r/components';
import ProtectedRoute from 'r/ProtectedRoute';
// Context.
import { AuthProvider, useAuth } from 'r/context/auth';
import { IntegrationsProvider } from 'r/context/integrations';
import { PublicBriefsProvider } from 'r/context/publicBriefs';
import { DashboardProvider } from 'r/context/dashboard';
import { GlobalErrorProvider } from 'r/context/globalError';
import { ComparandsProvider } from 'r/context/comparands';
import { ConceptsProvider } from 'r/context/concepts';
import { MkadsProvider } from 'r/context/mkads';
import { ImagePreviewProvider } from 'r/components/Dialog/ImagePreviewDialog';
// Pages.
const Integrations = lazy(() => import('r/pages/Integrations'));
const Dashboard = lazy(() => import('r/pages/Dashboard'));
const Compare = lazy(() => import('r/pages/Compare'));
const Performance = lazy(() => import('r/pages/Performance'));
const Enrichment = lazy(() => import('r/pages/Enrichment'));
const Downloads = lazy(() => import('r/pages/Downloads'));
const Login = lazy(() => import('r/pages/Login'));
// const Signup = lazy(() => import('r/pages/Signup'));
const ForgotPassword = lazy(() => import('r/pages/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() =>
  import('r/pages/ForgotPasswordSuccess')
);
const ResetPassword = lazy(() => import('r/pages/ResetPassword'));
const CreativePerformance = lazy(() => import('r/pages/Creative'));
const AdGeneration = lazy(() => import('r/pages/AdGeneration'));
const NotFound = lazy(() => import('r/pages/NotFound'));
const PublicBriefs = lazy(() => import('r/pages/PublicBriefs'));

const defaultTheme = createTheme(theme);

const AppRoutes = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const sid = searchParams.get('sid');
  const { user } = useAuth();
  let rootPage = '/downloads';

  const firstActivePage = user?.current_account?.pages?.find(
    (p) => p?.active === true
  );
  if (firstActivePage) {
    rootPage = firstActivePage?.link;
  }
  return (
    <DashboardProvider key={pathname}>
      <Routes>
        <Route element={<PageLayoutPublic />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<div />}>
                <Login />
              </Suspense>
            }
          />
          {/* <Route
            path="/signup"
            element={
              <Suspense fallback={<div />}>
                <Signup />
              </Suspense>
            }
          /> */}
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<div />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password-success"
            element={
              <Suspense fallback={<div />}>
                <ForgotPasswordSuccess />
              </Suspense>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <Suspense fallback={<div />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/logout"
            element={
              <Suspense fallback={<div />}>
                <Logout>Logging out...</Logout>
              </Suspense>
            }
          />
          <Route
            path="/downloads/:accountId"
            element={
              <PublicBriefsProvider>
                <Suspense fallback={<div />}>
                  <PublicBriefs />
                  <div style={{ height: '2000px' }} />
                </Suspense>
              </PublicBriefsProvider>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<div />}>
                <NotFound />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<PageLayoutSidebar />}>
            <Route
              path="/performance"
              element={
                <DashboardContainer page="performance">
                  <Suspense
                    fallback={
                      <PageLazyLoadSkeleton title="New Customer Predictions" />
                    }
                  >
                    <Performance />
                  </Suspense>
                </DashboardContainer>
              }
            />
            <Route
              path="/creative-audit"
              element={
                <ConceptsProvider>
                  <ImagePreviewProvider>
                    {/* when route changes, key prop will trigger remount */}
                    <ConceptsContainer key={`${pathname}-${sid}`}>
                      <Suspense
                        fallback={
                          <PageLazyLoadSkeleton title="Creative Performance" />
                        }
                      >
                        <CreativePerformance />
                      </Suspense>
                    </ConceptsContainer>
                  </ImagePreviewProvider>
                </ConceptsProvider>
              }
            />

            <Route
              path="/ad-generation"
              element={
                <MkadsProvider key={pathname}>
                  <Suspense
                    fallback={<PageLazyLoadSkeleton title="Ad Generation" />}
                  >
                    <AdGeneration />
                  </Suspense>
                </MkadsProvider>
              }
            />
            <Route
              path="/ad-generation/:sid"
              element={
                <MkadsProvider key={pathname}>
                  <Suspense
                    fallback={<PageLazyLoadSkeleton title="Ad Generation" />}
                  >
                    <AdGeneration />
                  </Suspense>
                </MkadsProvider>
              }
            />
            <Route
              path="/audiences"
              element={
                <DashboardContainer page="audiences">
                  <Suspense
                    fallback={<PageLazyLoadSkeleton title="All Customers" />}
                  >
                    <Dashboard />
                  </Suspense>
                </DashboardContainer>
              }
            />
            <Route
              path="/channels"
              element={
                <ComparandsProvider key={pathname}>
                  <DashboardContainer page="channels">
                    <Suspense
                      fallback={<PageLazyLoadSkeleton title="Channels" />}
                    >
                      <Compare page="Channels" />
                    </Suspense>
                  </DashboardContainer>
                </ComparandsProvider>
              }
            />

            <Route
              path="/campaigns"
              element={
                <ComparandsProvider key={pathname}>
                  <DashboardContainer page="campaigns">
                    <Suspense
                      fallback={<PageLazyLoadSkeleton title="Campaigns" />}
                    >
                      <Compare page="Campaigns" />
                    </Suspense>
                  </DashboardContainer>
                </ComparandsProvider>
              }
            />
            <Route
              path="/products"
              element={
                <ComparandsProvider key={pathname}>
                  <DashboardContainer page="products">
                    <Suspense
                      fallback={<PageLazyLoadSkeleton title="Products" />}
                    >
                      <Compare page="Products" />
                    </Suspense>
                  </DashboardContainer>
                </ComparandsProvider>
              }
            />

            <Route
              path="/integrations"
              element={
                <Suspense
                  fallback={
                    <PageLazyLoadSkeleton
                      title="Your Integrations"
                      fullHeight
                    />
                  }
                >
                  <Integrations />
                </Suspense>
              }
            />
            <Route
              path="/integrations/:integrationSlug"
              element={
                <Suspense
                  fallback={
                    <PageLazyLoadSkeleton
                      title="Your Integrations"
                      fullHeight
                    />
                  }
                >
                  <Integrations />
                </Suspense>
              }
            />
            <Route
              path="/integrations/:integrationSlug/callback"
              element={
                <Suspense
                  fallback={
                    <PageLazyLoadSkeleton
                      title="Your Integrations"
                      fullHeight
                    />
                  }
                >
                  <Integrations />
                </Suspense>
              }
            />
            <Route
              path="/enrichment"
              element={
                <Suspense
                  fallback={
                    <PageLazyLoadSkeleton title="Enrichment" fullHeight />
                  }
                >
                  <Enrichment />
                </Suspense>
              }
            />
            <Route
              path="/downloads"
              element={
                <PublicBriefsProvider>
                  <Suspense
                    fallback={
                      <PageLazyLoadSkeleton title="Downloads" fullHeight />
                    }
                  >
                    <Downloads />
                  </Suspense>
                </PublicBriefsProvider>
              }
            />
            <Route
              path="/"
              exact
              element={<Navigate replace to={rootPage} />}
            />
          </Route>
        </Route>
      </Routes>
    </DashboardProvider>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <TreatGlobalStyles />
        <GlobalErrorProvider>
          <AuthProvider>
            <IntegrationsProvider>
              <AppInitialize>
                <AppRoutes />
                <Amplitude />
              </AppInitialize>
            </IntegrationsProvider>
          </AuthProvider>
        </GlobalErrorProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
