import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Box, Link, ListItemIcon, ListItemText } from '@mui/material';
import NavItemSx from './NavItemSx.styles';

const NavItem = ({ itemLink, ItemIcon, itemText, open, disabled, sx }) => {
  const { linkStyles, iconStyles, textStyles } = NavItemSx(open);

  const renderLinkContent = () => (
    <>
      <ListItemIcon sx={iconStyles}>{ItemIcon && <ItemIcon />}</ListItemIcon>
      <ListItemText
        data-testid="sidebar-navitem-text"
        sx={textStyles}
        primaryTypographyProps={{
          variant: 'body',
        }}
      >
        {itemText}
      </ListItemText>
    </>
  );

  // If disabled, preserve the disabled styles but suppress
  // navigation for both mouse and keyboard.
  if (disabled) {
    return (
      <Box
        component="span"
        aria-disabled="true"
        data-href={itemLink}
        sx={{ ...linkStyles, pl: '17px', pr: '15px' }}
      >
        {renderLinkContent()}
      </Box>
    );
  }

  // Otherwise use the NavLink component to render an anchor el.
  return (
    <Link
      to={itemLink}
      component={NavLink}
      sx={{
        ...linkStyles,
        ...sx,
      }}
    >
      {renderLinkContent()}
    </Link>
  );
};

NavItem.defaultProps = {
  open: false,
  sx: {},
  props: {},
  disabled: false,
};

NavItem.propTypes = {
  itemLink: PropTypes.string.isRequired,
  ItemIcon: PropTypes.shape({}).isRequired,
  itemText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  sx: PropTypes.shape({}),
  props: PropTypes.shape({}),
};

export { NavItem };
