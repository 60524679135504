import React from 'react';
import { MenuItem, Popover, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { trtClientApi } from 'r/services';

const ProfileMenuOptions = [
  // {
  //   name: 'Profile',
  //   onClick() {},
  // },
  // {
  //   name: 'Security',
  //   onClick() {},
  // },
  // {
  //   name: 'Help',
  //   onClick() {},
  // },
  {
    name: 'Logout',
    onClick: async () => {
      await trtClientApi.get('/auth/logout');
      window.location.href = '/login';
    },
  },
];

const SettingMenu = ({ anchor, onClose, options = ProfileMenuOptions }) => {
  const theme = useTheme();

  const menuItemSx = {
    color: 'text.primary',
    fontSize: 14,
    lineHeight: '22px',
    px: 2.8,
    py: 1.4,
    minHeight: 36,
  };

  return (
    <Popover
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        zIndex: '1303',
        '& .MuiPopover-paper': {
          maxWidth: 200,
          width: '100%',
          boxShadow: theme.dropShadows.cardSoft,
          borderRadius: '4px',
          py: 1.2,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.common.border,
        },
      }}
    >
      {options.map((item) => (
        <MenuItem
          onClick={() => {
            item.onClick();
            onClose();
          }}
          sx={{
            ...menuItemSx,
            marginBottom: 0.2,
            '&:hover': {
              backgroundColor: theme.palette.background.gray,
            },
          }}
          key={item.name}
        >
          {item.name}
        </MenuItem>
      ))}
    </Popover>
  );
};

SettingMenu.propTypes = {
  anchor: PropTypes.instanceOf(Element).isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export { SettingMenu };
