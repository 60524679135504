import { bodyFontFamily, typeStyles } from './globalTypography';
import { parseTheme } from './utils';

const {
  typeDefaults,
  displayXXLargeBase,
  displayXLargeBase,
  displayLargeBase,
  displayMediumBase,
  displaySmallBase,
  bodyBase,
  headingBase,
  subheadingBase,
  captionBase,
  emailBase,
} = typeStyles;

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
      xxl: 1680,
      xxxl: 1700,
    },
  },
  spacing: 5,
  palette: {
    brandEmail: {
      primary: '#041249',
      secondary: '#FFFFFF',
    },
    common: {
      border: '#D2D2D2',
      borderLight: '#F3F3F3',
      borderEmail: '#D9D9D9',
    },
    primary: {
      main: '#2244CE',
      contrastText: '#FFFFFF',
    },
    treat: {
      brand: '#FFC062',
      text: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      white: '#FFFFFF',
      default: '#FFFFFF',
      blue: '#041249',
      yellow: '#FFC062',
      cyan: '#74C9D4',
      light: '#FAFAFA',
      backdrop: '#080D25',
      gray: '#F3F3F3',
    },
    text: {
      primary: '#080D25',
      default: '#080D25', // alt name for primary
      secondary: '#080D25',
      subdued: '#757575',
      disabled: '#C5C5C5',
      inactive: '#C5C5C5', // alt name for disabled
      highlight: '#9BD5DD',
      white: '#FFFFFF',
      grayed: '#9F9F9F',
    },
    interactive: {
      focus: '#2244CE',
      hover: '#082494',
      pressed: '#001E61',
      disabled: '#D2D2D2',
      inverse: '#F3F3F3',
      subdued: '#EDF5FF',
      white: '#FFFFFF',
      darkblue: '#080D25',
      skeleton: '#E7E7E7',
    },
    success: {
      main: '#00853C',
    },
    error: {
      main: '#D72C0D',
      metric: '#C22626',
    },
    action: {
      disabledBackground: '#D2D2D2',
      medYellow: '#D17D00',
      green: '#4BA811',
    },
    charts: {
      blue1: '#001E61',
      blue2: '#2D439C',
      blue3: '#3D62D3',
      blue4: '#518FD7',
      blue5: '#76ACDD',
      blue6: '#A4CAEC',
      green1: '#00757C',
      green2: '#038992',
      green3: '#1E9F9F',
      green4: '#64C0B5',
      green5: '#87CCBB',
      green6: '#C8E9D8',
      yellow1: '#D1830E',
      yellow2: '#E29421',
      yellow3: '#F8AC3B',
      yellow4: '#FFC062',
      yellow5: '#FFD586',
      yellow6: '#FDE0AA',

      blue1Hover: '#122E6C',
      blue2Hover: '#3E52A4',
      blue3Hover: '#4D6FD6',
      blue4Hover: '#5F98DA',
      blue5Hover: '#81B3E0',
      blue6Hover: '#AED0EE',
      green1Hover: '#148086',
      green2Hover: '#3BA3AA',
      green3Hover: '#30A7A7',
      green4Hover: '#72C6BC',
      green5Hover: '#93D1C2',
      green6Hover: '#D1ECDE',
      yellow1Hover: '#D58E24',
      yellow2Hover: '#E5A039',
      yellow3Hover: '#F9B551',
      yellow4Hover: '#FFC773',
      yellow5Hover: '#FFDA94',
      yellow6Hover: '#FDE6BB',

      blue1Recede: '#CCD2DF',
      blue2Recede: '#D5D9EB',
      blue3Recede: '#D8DFF6',
      blue4Recede: '#DCE9F7',
      blue5Recede: '#E3EEF8',
      blue6Recede: '#EDF4FB',
      green1Recede: '#CCE3E5',
      green2Recede: '#D4EBED',
      green3Recede: '#D2ECEC',
      green4Recede: '#E0F2F0',
      green5Recede: '#E7F5F1',
      green6Recede: '#F4FBF7',
      yellow1Recede: '#F6E6CF',
      yellow2Recede: '#F9EAD3',
      yellow3Recede: '#FEEED8',
      yellow4Recede: '#FFF2DF',
      yellow5Recede: '#FFF7E7',
      yellow6Recede: '#FFF9EE',
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontSize: typeDefaults.fontSize,
    fontWeightRegular: typeDefaults.fontWeightRegular,
    fontWeightMedium: typeDefaults.fontWeightMedium,
    fontWeightBold: typeDefaults.fontWeightBold,
    // Remove the default typography variants.
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    body1: undefined,
    body2: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    // Add Treat typography variants.
    displayXXLarge: {
      ...displayXXLargeBase,
    },
    displayXLarge: {
      ...displayXLargeBase,
    },
    displayXLargeSubdued: {
      ...displayXLargeBase,
      color: 'palette.text.subdued',
    },
    displayLarge: {
      ...displayLargeBase,
    },
    displayLargeSubdued: {
      ...displayLargeBase,
      color: 'palette.text.subdued',
    },
    displayMedium: {
      ...displayMediumBase,
    },
    displayMediumSubdued: {
      ...displayMediumBase,
      color: 'palette.text.subdued',
    },
    displaySmall: {
      ...displaySmallBase,
    },
    displaySmallSubdued: {
      ...displaySmallBase,
      color: 'palette.text.subdued',
    },
    body: {
      ...bodyBase,
    },
    bodySemibold: {
      ...bodyBase,
      fontWeight: 600,
    },
    bodyPowered: {
      ...bodyBase,
      ...emailBase,
      fontSize: '1rem',
      strong: {
        fontSize: '1.2rem',
        fontWeight: 700,
      },
    },
    bodySubdued: {
      ...bodyBase,
      color: 'palette.text.subdued',
    },
    heading: {
      ...headingBase,
    },
    headingSubdued: {
      ...headingBase,
      color: 'palette.text.subdued',
    },
    subheading: {
      ...subheadingBase,
    },
    subheadingSubdued: {
      ...subheadingBase,
      color: 'palette.text.subdued',
    },
    caption: {
      ...captionBase,
    },
    captionSubdued: {
      ...captionBase,
      color: 'palette.text.subdued',
    },
    captionSemibold: {
      ...captionBase,
      fontFamily: bodyFontFamily,
      fontWeight: 600,
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
    },
    captionSemiboldSubdued: {
      ...captionBase,
      fontFamily: bodyFontFamily,
      fontWeight: 600,
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: 'palette.text.subdued',
    },
    captionPublicSubdued: {
      ...captionBase,
      fontWeight: 400,
      color: 'palette.text.subdued',
      a: {
        fontWeight: 600,
        color: 'palette.interactive.focus',
        textDecoration: 'none',
      },
    },
    captionXS: {
      ...captionBase,
      fontSize: '1rem',
      lineHeight: 1.4,
    },
    captionXSSemibold: {
      ...captionBase,
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.4,
    },
    captionXSSubdued: {
      ...captionBase,
      fontSize: '1rem',
      lineHeight: 1.4,
      color: 'palette.text.subdued',
    },
    tileLargeValue: {
      fontFamily: bodyFontFamily,
      fontSize: '2.6rem',
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: '-1px',
    },
    tileMediumValue: {
      fontFamily: bodyFontFamily,
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: '-0.25px',
    },
    emailHeadline: {
      ...emailBase,
      fontSize: '3.4rem',
      lineHeight: 1.2,
    },
    emailSubheading: {
      ...emailBase,
      fontSize: '2.1rem',
      lineHeight: 1.2,
    },
    emailBody: {
      ...emailBase,
      fontSize: '1.7rem',
      lineHeight: 1.5,
      strong: {
        fontWeight: 700,
      },
    },
    emailBodyInactive: {
      ...emailBase,
      fontSize: '1.7rem',
      lineHeight: 1.5,
      color: 'palette.text.inactive',
      strong: {
        fontWeight: 700,
      },
    },
    emailCaption: {
      ...emailBase,
      fontSize: '1.6rem',
      lineHeight: 1,
      fontWeight: 700,
      textTransform: 'uppercase',
      '&,&:hover,&:focus': {
        textDecoration: 'none',
      },
    },
  },
  transitions: {
    easing: {
      bounce: 'cubic-bezier(.15,.7,.95,1.1)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '62.5%', // 1rem = 10px.
        },
        'p.MuiTypography-root': {
          marginBottom: '16px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'palette.interactive.skeleton',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '50px',
          borderWidth: '1px',
          fontSize: typeDefaults.fontSize,
          fontWeight: typeDefaults.fontWeightBold,
          textTransform: 'uppercase',
          letterSpacing: '0.5px',
          minWidth: '160px',
          padding: '15px 30px',
        },
        text: {
          borderRadius: '0',
          display: 'inline-block',
          fontWeight: typeDefaults.fontWeightMedium,
          textTransform: 'none',
          letterSpacing: 0,
          padding: 0,
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          '&.MuiButton-sizeMedium, &.MuiButton-sizeSmall, &.MuiButton-sizeLarge':
            {
              minWidth: 0,
              padding: 0,
            },
        },
        outlined: {
          backgroundColor: 'palette.interactive.white',
          '&:hover': {
            backgroundColor: 'palette.interactive.focus',
            color: 'palette.text.white',
          },
          '&:focus': {
            backgroundColor: 'palette.interactive.focus',
            color: 'palette.text.white',
          },
        },
        contained: {
          '&:hover': {
            backgroundColor: 'palette.interactive.hover',
            borderColor: 'palette.interactive.hover',
          },
          '&:focus': {
            backgroundColor: 'palette.interactive.pressed',
            borderColor: 'palette.interactive.pressed',
          },
          '&.Mui-disabled': {
            color: 'palette.interactive.white',
            borderColor: 'palette.action.disabledBackground',
          },
        },
        containedPrimary: {
          '&.with-thick-border': {
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: 'palette.primary.main',
            padding: '11px 26px',
          },
        },
        sizeSmall: {
          fontSize: '1.2rem',
          minWidth: '120px',
          padding: '8px 23px',
        },
        containedSizeSmall: {
          '&.with-thick-border': {
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: 'palette.primary.main',
            padding: '4px 19px',
          },
        },
        sizeMedium: {
          minWidth: '140px',
          padding: '9px 25px',
        },
        containedSizeMedium: {
          '&.with-thick-border': {
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: 'palette.primary.main',
            padding: '5px 21px',
            '&.Mui-disabled': {
              borderColor: 'palette.action.disabledBackground',
            },
            '&:hover': {
              borderColor: 'palette.interactive.hover',
            },
            '&:focus': {
              borderColor: 'palette.interactive.pressed',
            },
          },
        },
        inputStyle: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'palette.common.border',
          borderRadius: '4px',
          fontSize: '12px',
          fontWeight: 400,
          height: '30px',
          color: 'palette.text.default',
          textTransform: 'none',
          letterSpacing: 0,
          '&.MuiButton-root': {
            minWidth: 'max-content',
            padding: '3.5px 10px',
          },
          '&:hover, &:focus': {
            backgroundColor: 'rgba(8, 13, 37, 0.04)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'brandEmail' },
          style: {
            borderRadius: '6px',
            backgroundColor: 'palette.brandEmail.primary',
            color: 'palette.brandEmail.secondary',
            '&:hover, &:focus': {
              backgroundColor: 'palette.brandEmail.primary',
              color: 'palette.brandEmail.secondary',
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: false,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: 400,
          marginLeft: 0,
          marginTop: '5px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayXXLarge: 'h1',
          displayXLarge: 'h1',
          displayLarge: 'h2',
          displayMedium: 'h3',
          displaySmall: 'h4',
          h5: 'h4',
          h6: 'h4',
          heading: 'h4',
          subheading: 'h4',
          caption: 'span',
          captionSemibold: 'span',
          captionPublicSubdued: 'span',
          tileLargeValue: 'span',
          bodyPowered: 'p',
          bodySemibold: 'p',
          emailHeadline: 'h1',
          emailSubheading: 'h2',
          emailBody: 'p',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          fontWeight: typeDefaults.fontWeightMedium,
          '&:hover, &:focus': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          '&:hover': {
            backgroundColor: '#f3f3f3',
          },
          '&[aria-selected=true]': {
            backgroundColor: '#f8f8f8',
          },
          '&[aria-selected=true]:hover': {
            backgroundColor: '#f3f3f3',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&:not(.MuiBackdrop-invisible)': {
            backgroundColor: 'rgba(8, 13, 37, 0.6)',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        container: {
          '[role=dialog]': {
            padding: '80px',
            boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.03)',
            borderRadius: '8px',
            zIndex: 1303,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...displayXLargeBase,
          textAlign: 'center',
          padding: '0 0 12px',
          margin: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '35px 0 0 0',
          gap: '18px',
          justifyContent: 'center',
          // Override default margin
          '& > :not(:first-of-type)': {
            margin: 0,
          },
          // Stretch buttons there is more than one
          '& > :not(:first-of-type:last-of-type)': {
            flex: 1,
          },
        },
      },
    },
  },
  dropShadows: {
    topNav: '0px 3px 4px rgba(0, 0, 0, 0.05)',
    leftNav: '3px 3px 4px rgba(0, 0, 0, 0.05)',
    alertBox: '3px 3px 4px rgba(0, 0, 0, 0.03)',
    cardSoft: '2px 2px 6px rgba(0, 0, 0, 0.03)',
    dropSoft: '2px 2px 6px rgba(0, 0, 0, 0.10)',
    callout: '1px 2px 7px rgba(0, 0, 0, 0.15)',
    preview: '6px 6px 20px rgba(0, 0, 0, 0.06)',
  },
  borderRadius: {
    default: '4px',
    rounded: '8px',
  },
};

parseTheme(theme);

export default theme;
