import { node } from 'prop-types';
import React, { useContext, createContext } from 'react';
import { publicBriefsApi as api } from './publicBriefsApi';

const PublicBriefsContext = createContext();

function PublicBriefsProvider({ children }) {
  const [publicBriefs, setPublicBriefs] = React.useState(null);
  const value = React.useMemo(
    () => ({
      api,
      publicBriefs,
      setPublicBriefs,
    }),
    [publicBriefs]
  );
  return (
    <PublicBriefsContext.Provider value={value}>
      {children}
    </PublicBriefsContext.Provider>
  );
}

function usePublicBriefs() {
  const context = useContext(PublicBriefsContext);

  if (context === undefined) {
    throw new Error(
      'usePublicBriefs must be used within an PublicBriefsProvider'
    );
  }
  return context;
}

PublicBriefsProvider.propTypes = {
  children: node.isRequired,
};

export { PublicBriefsProvider, usePublicBriefs, PublicBriefsContext };
